'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.7 Manifestação do destinatário de NF-e

Funcionalidade onde a empresa poderá informar a manifestação acerca de suas notas destinadas à SEFAZ.

#### Requisição a enviar

    POST /api/v1/{modelo}/{id}/manifestar/{manifestacao}
    POST /api/v1/{modelo}/{id}/manifestar/{manifestacao}?justificativa={justificativa}
    POST /api/v1/{modelo}/{id}/manifestar/{manifestacao}?cnpj={cnpj}

#### Parâmetros

Parâmetro        | Conteúdo
---------------- | -------------
\`modelo\`       | para NF-e: \`nfe\` <br/> para CT-e: \`cte\`
\`id\`           | id ou chave do documento para o qual está realizando a manifestação
\`manifestacao\` | para "confirmação da operação": \`confirmada\` <br/> \
                   para "desconhecimento da operação": \`desconhecida\` <br/> \
                   para "operação não realizada": \`operacao_nao_realizada\` <br/> \
                   para "ciência da operação": \`ciencia\`
\`justificativa\`| a justificativa da manifestação, obrigatória no caso de \`operacao_nao_realizada\`
\`cnpj\`         | CNPJ do destinatário, obrigatório apenas se o documento não existir no sistema    

#### Respostas possíveis

##### 200: manifestação informada

Essa resposta significa que a manifestação foi recebida e enviada à SEFAZ com sucesso.

##### 400: requisição inválida

Significa que a requisição não foi aceita, normalmente por falta de parâmetros ou valor inválido.

\`\`\`
{ "error": "O parâmetro 'justificativa' é obrigatório em caso de operação não realizada." }
\`\`\`

\`\`\`
{ "error": "Modelo inválido" }
\`\`\`

##### 403: ação não permitida

Significa que o documento existe, mas sua chave de acesso não tem permissão para fazer a manifestação.

\`\`\`
{ "error": "Ação não permitida" }
\`\`\`

##### 404: documento não encontrado

Significa que o documento não existe e não é possível criá-lo a partir dos parâmetros ou o usuário não tem permissão para acessar o documento. 

\`\`\`
{ "error": "Documento não encontrado" }
\`\`\`

##### 422: Requisição inválida

Significa que o corpo da requisição não segue o formato especificado.

\`\`\`
{ "error": "Requisição não atende ao formato especificado" }
\`\`\`

##### 422: CNPJ não possui permissão para manifestar

Significa que o usuário não tem permissão para manifestar com o CNPJ informado na requisição.  

\`\`\`
{ "error": "Você não possui permissão para manifestar com o CNPJ informado." }
\`\`\`

##### 449: certificado necessário

Significa que a requisição não pode ser feita, pois não há certificado A1 cadastrado no sistema para esta operação.

\`\`\`
{ "error": "Não foi encontrado certificado digital para o CNPJ: 10967573000142." }
\`\`\`

##### 502: erro na SEFAZ

Significa que a SEFAZ rejeitou a manifestação. O corpo da mensagem conterá a resposta da SEFAZ.

\`\`\`
{ "error": "SEFAZ retornou código de erro.",
  "status": 573,
  "motivo": "Rejeicao: Duplicidade de evento" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. Se o erro persistir, contatar o suporte.

\`\`\`
{ "error": "Bad server, no donut for you." }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X POST 'https://portal.consyste.com.br/v1/nfe/53d2f08f9711f6abe20009e7/manifestar/ciencia'
\`\`\``;

export default function Introducao() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}