'use strict';

import $ from 'jquery';

let lastId = 0;

$(function() {
  const $body = $('body');

  // se temos uma div#selecao, ativamos o controle de checkboxes
  if ($('#selecao').length > 0) {
    $body.on('ajax-loaded', function(event) {
      const selectedValues = $("#selecao").find("input").map(function(i, obj) { return obj.value; }).get();
      $('input:checkbox', event.target).each(function() {
        $(this).prop('checked', selectedValues.indexOf(this.value) >= 0).change();
      });
    });
  }

  // ajax links
  $body.on('click', 'a[data-relative-target], a[data-target]', function(e) {
    const $a = $(this);
    if ($a.data('toggle'))
      return;
    let $target, targetSpec;
    if ((targetSpec = $a.data('relativeTarget')))
      $target = $a.closest(targetSpec);
    else if ((targetSpec = $a.data('target')))
      $target = $(targetSpec);
    else
      return;

    e.preventDefault();
    $target.mask('Aguarde…', 250);
    $target.load($a.attr('href'), function() {
      $target.trigger('ajax-loaded');
      $target.unmask();
    });
  });

  // ajax form
  $body.on('submit', 'form.form-ajax', function() {
    const $form      = $(this),
          targetSpec = $form.data('target');

    let $target;
    if (targetSpec)
      $target = $(targetSpec);
    else if ($form.closest('.modal-dialog').length !== 0)
      $target = $form.closest('.modal-dialog').find('.modal-body');
    else
      $target = $form;

    $target.find('.alert').hide();
    $target.mask('Aguarde…', 250);

    // prepara função para limpeza
    const cleanup = function() {
      $form.find('.btn[data-loading-text]').button('reset');
      $target.unmask();
    };

    const formData = $form.serialize();
    $.ajax({ type: $form.attr('method'), url: $form.attr('action'), data: formData })
        .done(function(data) {
          // se o retorno for JSON, tenta interpretar.
          // no momento, interpretamos apenas { "redirect": "url" }
          if (typeof data === 'object') {
            if (data.redirect) {
              location.href = data.redirect;
              return;
            }
            // se não for possível interpretar o JSON, retorna o mesmo na tela, com mensagem de erro
            $target.html(`Retorno JSON não tratado: <pre>${data}</pre>`);
          }
          else {
            // se o retorno for HTML, atribui ao data-target do form
            $target.html(data).trigger('ajax-loaded');
            window.history.pushState(null, null, `?${formData}`);
            $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
          }

          // faz a limpeza da tela (reativa botões, etc), exceto em caso de redirect
          cleanup();
        })
        .fail(function(xhr) {
          let msg = 'Ocorreu um erro na comunicação.';
          if (xhr.getResponseHeader('Content-Type').indexOf('application/json') === 0) {
            try {
              const json = $.parseJSON(xhr.responseText);
              if (json.error)
                msg = json.error;
            }
            catch (e) {
              // do nothing
            }
          }

          $(`<div class="alert alert-danger">${msg}</div>`).prependTo($target);
          $target.focusFirst();
          cleanup();
        });

    return false;
  });

  // ajax pagination with checkbox preservation
  $('.ajax-container')
      .on('click', '.pagination a, a[target=_ajax-container]', function() {
        const $link = $(this);
        const $target = $link.closest('.ajax-container');
        $target.mask('Aguarde…', 250);
        const url = this.href;
        $target.load(url, function() {
          $target.trigger('ajax-loaded');
          $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
          if (!$link.data('ignorePushState'))
            window.history.pushState(null, null, url.replace(/^.*\?/, '?'));
          $target.unmask();
        });
        return false;
      })
      .on('change', 'input:checkbox', function() {
        const $ck = $(this);
        const $selecao = $("#selecao");
        $selecao.find(`input[name='c[]'][value='${$ck.prop('value')}']`).remove();
        if ($ck.prop('checked'))
          $selecao.append($(`<input type='hidden' name='c[]' value='${$ck.prop('value')}'>`));
      })
      .on('click', "a[href='#select-all']", function() {
        const $link = $(this);
        const $sel = $('#selecao');
        $sel.empty();
        $.map($link.data('values').split(':'), function(v) {
          $sel.append($(`<input type='hidden' name='c[]' value='${v}'>`));
        });

        $link.closest('.ajax-container').find('input:checkbox').prop('checked', true).change();
        return false;
      });


  function makeErrorModal(title, body) {
    return `
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header"><h4>${title}</h4></div>
          <div class="modal-body">${body}</div>
          <div class="modal-footer"><button class="btn btn-default" data-dismiss="modal">Fechar</button></div>
        </div>
      </div>
    `;
  }

  // ajax modals
  $body.on('click', "*[data-toggle='ajax-modal']", function() {
    const $link = $(this);
    if ($link.hasClass('disabled'))
      return false;

    const targetSelector = $link.data('target');
    let $target = $(targetSelector);

    if ($target.length === 0) {
      const attrs = { 'tabindex': '-1', 'role': 'dialog' };
      attrs['class'] = 'modal';
      if ($body.data('env') !== 'test')
        attrs['class'] += ' fade';
      if ($link.data('targetClass'))
        attrs['class'] += ` ${$link.data('targetClass')}`;
      if (targetSelector && targetSelector.substring(0, 1) === '#')
        attrs['id'] = targetSelector.substring(1);
      if (!attrs['id'] || document.getElementById(attrs['id']))
        attrs['id'] = `ajax-modal-${++lastId}`;
      $target = $('<div>', attrs).appendTo($('.content'));
      $link.data('target', `#${$target.attr('id')}`);
    }

    $body.mask('Aguarde…', 250);

    $.get($link.attr('href') || $link.data('url')).done(function(r) {
      $target.html(r);
    }).fail(function() {
      $target.html(makeErrorModal('Erro', `
        <p>Ocorreu um erro ao realizar esta operação.</p>
        <p>Nossa equipe de suporte irá receber uma notificação a respeito.
           Em caso de dúvidas, entre em contato pelo e-mail suporte@consyste.com.br.</p>
      `));
    }).always(function() {
      $body.unmask();
      $target.trigger('ajax-loaded').modal('show', { remote: false });
    });
    return false;
  });

  // ajax divs
  $('*[data-load-url]').each(function() {
    const el = $(this);
    el.mask('Aguarde…', 100);
    $.get(el.data('loadUrl'))
        .done((data, textStatus, jqXHR) => {
          if (jqXHR.getResponseHeader('Content-Type').startsWith('text/plain'))
            el.text(data);
          else
            el.html(data);

          el.trigger('ajax-loaded');
          el.unmask();
        });
  });

  // ajax autocomplete

  function formatAutocompleteResultWithLasers(suggestion, currentValue) {
    const escapes  = ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\'].join('|\\'),
          reEscape = new RegExp(`(\\${escapes})`, 'g'),
          pattern  = `(${currentValue.replace(reEscape, '\\$1').split(' ').join('|')})`;

    return suggestion.value.replace(new RegExp(pattern, 'gi'), '<strong>$1</strong>');
  }

  $('input[id^=autocomplete_for_]').each(function(index, input) {
    input = $(input);
    const field = $(input.data('field'));
    input.blur(function() {
      if (!input.val().trim())
        field.val('')
    });
    input.click(function() { this.select(); });
    input.autocomplete({
      serviceUrl: input.data('source'),
      minChars: input.data('minChars'),
      onSelect(s) { field.val(s.data); },
      formatResult: formatAutocompleteResultWithLasers
    })
  });

  $('.autocomplete_selections a.remove').click(function() {
    $(this).closest('li').remove();
  });

  $('input[id^=autocomplete_multiple_for_]').each(function(index, input) {
    $(input).click(function() {
      this.select();
    }).autocomplete({
      serviceUrl: $(input).data('source'),
      minChars: $(input).data('min-chars'),
      onSelect(value, data) {
        const template = $(input).data('template').replace('ID', data).replace('VALUE', value);
        $($(input).data('selections')).append($(`<div>${template}</div>`).text());
        $(input).val('');
      },
      formatResult: formatAutocompleteResultWithLasers
    })
  });
});
