'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';

import { withProvider } from '../../../../lib/reduxTools';
import { atualizaDescricao } from '../../../../reducers/admin/politica_senha';

import  PoliticaSenha  from './politica_senha';

export default compose(
    withProvider(),
    connect(null, { atualizaDescricao })
)(PoliticaSenha);
