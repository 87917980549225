// @flow
'use strict';

import * as Immutable from 'immutable';
import * as React from 'react';

import Tabela from './tabela';

type ListagemItensProps = {
  itens : Immutable.List<*, *>
}

export default function ListagemItens({ itens } : ListagemItensProps) {
  return (
      <div className="apos-campo">
        <p>
          Se for o caso, selecione os itens com pendência:
        </p>
        <Tabela dados={ itens }/>
      </div>
  );
}