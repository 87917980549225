'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.9 Emissão de NF-e

<p style="margin-left: 5px;">Realiza a emissão de uma NF-e através de um TXT, podendo também consultar o status de uma emissão já solicitada.</p>

#### 3.9.2 Consultar Solicitação de Emissão

O cliente envia o ID de uma solicitação de emissão de NFe e o sistema retorna a chave e o status da NFe no Emissor.

#### Requisição a enviar

Para consultar a situação da emissão de uma Nfe

    GET /api/v1/emissao/{amb}/nfe/{id}

#### Parâmetros

Parâmetro     | Conteúdo
------------- | -------------
\`id\`        | ID da NFe desejada.
\`amb\`       | Ambiente em que a emissão foi solicitada (1 - Produção e 2 - Homologação).

#### Exemplo

    GET /api/v1/emissao/2/nfe/62b080477bbe81e57e06b5bc

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z'
     'https://portal.consyste.com.br/api/v1/emissao/2/nfe/62b080477bbe81e57e06b5bc'
\`\`\`

#### Respostas possíveis

##### 200: consulta concluída

Retorna o status da NFe e a sua chave.

\`\`\`
{
  "status": "Em digitação",
  "chave": "43190700383101000199550010000001611721485935"
}
\`\`\`

##### 202: ID informado inexistente

Significa que o ID informado está incorreto.

\`\`\`
{ "alerta": "Nenhuma NFe com o ID informado foi localizada" }
\`\`\`

##### 422: requisição inválida

Significa que a requisição não possui o formato especificado.

\`\`\`
{ "error": "Requisição não atende ao formato especificado" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Banco de dados fora do ar" }
\`\`\``;

export default function ConsultaEmissaoNfe() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}
