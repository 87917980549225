'use strict';
// @flow

import { compose } from 'redux';
import { connect } from 'react-redux';

import { withProvider } from '../../../lib/reduxTools';

import Filtros from './filtros';

export default compose(
    withProvider(),
    connect(),
)(Filtros);
