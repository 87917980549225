'use strict';

import $ from 'jquery';

$.fn.setFilledOrMaxed = function() {
  const el  = $(this),
        val = Number(el.val()),
        max = Number(el.prop('max'));

  el.toggleClass('filled', val > 0).toggleClass('maxed', val > 0 && val === max);
  return el;
};

$(function() {
  // clickable table rows
  $('table').on('click', 'tr[data-url]', function(e) {
    if (!$(e.target).is('a'))
      location.href = $(this).data('url');
  });

  // disabled rows
  $('table tr.disabled input').each(function() {
    $(this).prop('disabled', true);
  });

  const $body = $('body');

  // clique nas checkboxes, evita propagar para as TDs
  $body.on('click', 'table.with-checkboxes td input:checkbox, table.with-checkboxes td input:radio, table.with-checkboxes td label', function(e) {
    e.stopPropagation();
  });

  // permissão para clicar na célula ao invés de apenas na checkbox diretamente
  $body.on('click', 'table.with-checkboxes td', function() {
    const ck = $('input:checkbox, input:radio', this);
    if (!ck || ck.length === 0 || ck.closest('td').get(0) !== $(this).get(0))
      return;

    if (ck.prop('disabled'))
      return false;
    ck.click();
    ck.change();
  });

  // alteração nas checkboxes
  $body.on('change', 'table.with-checkboxes input:checkbox', function() {
    const ck = $(this);
    if (!ck.closest('table').is('.do-not-select-rows'))
      ck.closest('tr').toggleClass('selected', ck.prop('checked'));
  });

  // clique no cabeçalho das checkboxes
  $body.on('click', 'table.with-checkboxes th.check', function() {
    const ix    = $(this).cellPos().left,
          table = $(this).closest('table');

    let firstCk = null;

    $('tbody tr td input[type=checkbox], tbody tr td input[type=radio]', table).each(function() {
      if (!firstCk && $(this).closest('td').index() === ix)
        firstCk = this;
    });

    if (firstCk) {
      const c = firstCk.type === 'radio' || !firstCk.checked;

      $('input[type=checkbox], input[type=radio]', table).each(function() {
        if ($(this).closest('td').index() === ix && !this.disabled) {
          if (c || this.type !== 'radio') {
            this.checked = c;
            $(this).change();
          }
        }
      });
    }
  });

  // visual identification of maxed-out and zero'd numeric inputs on tables
  $('table.table').on('change', 'input[type=number]', function() {
    $(this).setFilledOrMaxed();
  });
});
