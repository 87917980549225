'use strict';
// @flow

import React from 'react';
import { Button, Table } from 'react-bootstrap';

import Icone from 'components/common/icone';
import NoDataRow from 'components/common/no_data_row';
import ProgressoCarregamento from 'components/common/progresso_carregamento';

import { formatHelper as fmt } from 'lib/viewUtils';

import type { IDownload, IDownloads } from './types';

export default function TabelaDownloads({ dados } : { dados? : IDownloads }) {
  return (
      <div>
        <Table striped>
          <thead>
          <tr>
            <th style={ { width: '11em' } }>Solicitado em</th>
            <th style={ { width: '5em' } }>Formato</th>
            <th style={ { width: '4em' } }>Tipo</th>
            <th style={ { width: '5em' } }>Total</th>
            <th>Progresso</th>
            <th style={ { width: '11em' } }>Download em</th>
            <th style={ { width: '6.5em' } }/>
          </tr>
          </thead>
          <Body cols={ 7 } dados={ dados }/>
        </Table>
      </div>
  );
}

function Body({ cols, dados } : { cols : number, dados? : IDownloads }) {
  let rows;

  if (!dados)
    rows = <NoDataRow span={ cols }> Nenhum download solicitado. </NoDataRow>;
  else if (dados === 'loading')
    rows = <NoDataRow span={ cols }> <Icone nome="sync-alt" estilo="fas" tamanho={2} spin /> </NoDataRow>;
  else if (dados === 'erro')
    rows = <NoDataRow span={ cols }> Ocorreu um erro ao carregar a lista de downloads. </NoDataRow>;
  else if (dados.length === 0)
    rows = <NoDataRow span={ cols }> Nenhum registro encontrado. </NoDataRow>;
  else
    rows = dados.map(d => <Linha { ...d } key={ d.id }/>);

  return <tbody>{ rows }</tbody>;
}

function Linha({ total, atual, erros, created_at, formato, tipo, concluido_em, download_realizado_em, arquivo, id } : IDownload) {
  return <tr>
    <td className="c">{ fmt.formatDateTime(created_at) }</td>
    <td className="c">{ formato && formato.label }</td>
    <td className="c">{ tipo && tipo.label }</td>
    <td className="c">{ fmt.numberWithDelimiter(total) || '–' }</td>
    <td>
      <ProgressoCarregamento total={ total } acertos={ atual - (erros || 0) } erros={ erros } id={ id }/>
    </td>
    <td className="c">{ download_realizado_em && fmt.formatDateTime(download_realizado_em) }</td>
    <td>
      <Button bsSize="xs" disabled={ !concluido_em } href={ arquivo && arquivo.url }>
        <Icone nome="cloud-download" estilo="fas">download</Icone>
      </Button>
    </td>
  </tr>;
}
