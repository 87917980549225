'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.4 Download de Documento

Obtém o XML ou PDF de um documento constante no Portal.

#### Requisição a enviar

Para obter o XML ou PDF de um documento pelo seu ID

    GET /api/v1/{modelo}/{id}/download.{formato}

Para obter o XML de um documento pela sua chave

    GET /api/v1/{modelo}/{chave}/download.{formato}

#### Parâmetros

Parâmetro     | Conteúdo
------------- | -------------
\`modelo\`    | para NF-e: \`nfe\` <br/> para CT-e: \`cte\`
\`formato\`   | para XML: \`xml\` <br/> para PDF: \`pdf\`

#### Respostas possíveis

##### 200: dados do documento

Retorna o XML ou PDF do documento:

\`\`\`
<?xml version="1.0"?>
<nfeProc xmlns="http://www.portalfiscal.inf.br/nfe" versao="2.00">
  <NFe xmlns="http://www.portalfiscal.inf.br/nfe">
    <infNFe versao="2.00" Id="NFe31150309004072000181550010000010331000112657">
      <ide> ... </ide>
      <emit> ... </emit>
      <dest> ... </dest>
      <det nItem="1"> ... </det>
      <total> ... </total>
      <transp> ... </transp>
    </infNFe>
    <Signature xmlns="http://www.w3.org/2000/09/xmldsig#"> ... </Signature>
  </NFe>
  <protNFe versao="2.00">
    <infProt Id="ID131151701097860"> ... </infProt>
  </protNFe>
</nfeProc>
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Chave ou identificador inválido" }
\`\`\`

##### 403: ação não permitida

Significa que o documento existe, mas sua chave de acesso não tem permissão para fazer o download do XML.

\`\`\`
{ "error": "Ação não permitida" }
\`\`\`

##### 404: documento não encontrado

Significa que o documento não existe, ou a sua chave de acesso não tem acesso a ele.

\`\`\`
{ "error": "Documento não encontrado" }
\`\`\`

##### 422: documento indisponível

Significa que o documento existe, mas o XML do documento não está disponível.

\`\`\`
{ "error": "XML indisponível" }
\`\`\`

##### 429: muitas requisições

Significa que ocorreram muitas requisições em um curto espaço de tempo utilizando sua chave de acesso.

\`\`\`
{ "error": "Muitas requisições" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Banco de dados fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfe/551045bf706f725f36272201/download.pdf'
\`\`\``;

export default function DownloadDocumentos() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}