'use strict';
// @flow

import * as React from 'react';

import { Col, Panel, ProgressBar, Row } from 'react-bootstrap';

import Icone from 'components/common/icone';
import PaineisPortaria from 'components/paineis/paineis_portaria';

import DadosDocumento from './dados_documento';
import DecisaoPortaria from './decisao_portaria';

import { BotaoImprimir, BotaoMaisInformacoes, BotaoRecuperar, BotaoDownload } from './botoes';

import type { IResultadoConsultaSefaz, ResultadoProps } from './types';

export default function Resultado(props : ResultadoProps) {
  const { actions, consulta, consultaDocumento, consultaSefaz } = props;

  if (consultaDocumento.carregando)
    return <Row><Col md={ 7 } mdOffset={ 1 }><Aguarde/></Col></Row>;

  const errorMessage = consultaDocumento.erro && consultaDocumento.erro.message;

  if (errorMessage)
    return <Row><Col md={ 7 } mdOffset={ 1 }><ErroGenerico>{ errorMessage }</ErroGenerico></Col></Row>;

  if (!consulta)
    return null; // nada a exibir

  return (
      <Row>
        <Col md={ 9 }>
          <div className="panel panel-info">
            <div className="panel-heading"><strong>Dados do Documento</strong></div>
            <div className="panel-body">
              <DadosDocumento consulta={ consulta } actions={ actions }/>
            </div>
            <div className="panel-body info-portaria acoes-basicas">
              <ConsultandoSefaz consultaSefaz={ consultaSefaz }/>
              <div className="pull-right">
                <BotaoImprimir consulta={ consulta }/> { ' ' }
                <BotaoDownload consulta={ consulta }/> { ' ' }
                <BotaoMaisInformacoes consulta={ consulta }/>
              </div>
            </div>
            <DecisaoPortaria consulta={ consulta }/>
          </div>
        </Col>
        <Col md={ 3 }>
          <PaineisPortaria documento={ consulta.documento }/>
          <BotaoRecuperar consulta={ consulta } actions={ actions }/>
        </Col>
      </Row>
  );
}

export function Aguarde() {
  return (
      <ProgressBar active now={ 100 } label="Aguarde, consultando…"/>
  );
}

export function ConsultandoSefaz({ consultaSefaz } : { consultaSefaz? : ?IResultadoConsultaSefaz }) {
  if (!consultaSefaz || !consultaSefaz.carregando)
    return null;

  return (
      <Icone nome="circle-notch" estilo="fas" spin className="pull-left" style={ { color: '#666', marginTop: '.7em' } }>
        <small>Consultando a SEFAZ…</small>
      </Icone>
  );
}

export function ErroGenerico({ children } : { children? : any }) {
  return (
      <Panel bsStyle="danger">
        <Panel.Heading>Erro</Panel.Heading>
        <Panel.Body>{ children }</Panel.Body>
      </Panel>
  );
}