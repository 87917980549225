'use strict';

// @flow

import * as React from 'react';
import { Button, Col, Panel, Row } from 'react-bootstrap';

import { toNumberBr, toNumberBrDecimal } from '../../lib/toNumberBrDecimal';

import DefinitionList from '../common/definition_list';

type InfoTwoCaptchaProps = {
  nome : string,
  urlRecarga : string,
  saldo : number,
  recuperacoes : number,
};

export default function InfoTwoCaptcha({ nome, urlRecarga, saldo, recuperacoes } : InfoTwoCaptchaProps) {
  return (
      <Panel className="info">
        <Panel.Heading>Informações do serviço de CAPTCHA</Panel.Heading>
        <Panel.Body>
          <Row>
            <Col sm={ 8 }>
              <DefinitionList>
                { { label: 'Serviço', value: nome } }
                { { label: 'Saldo (US$)', value: toNumberBrDecimal(saldo, 2) } }
                { { label: 'Recuperações', value: toNumberBr(recuperacoes) } }
              </DefinitionList>
            </Col>
            <Col sm={ 4 }>
              <div className="pull-right">
                <Button type="button" bsStyle="success" href={ urlRecarga } target="_blank">
                  Recarregar
                </Button>
              </div>
            </Col>
          </Row>
        </Panel.Body>
      </Panel>
  );
}
