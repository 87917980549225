'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 1 Sobre o Documento

Este documento especifica todas as operações disponíveis para integração do Portal Consyst-e com sistemas de clientes.

## 2 Orientações Gerais

O endereço para o ambiente de produção é: https://portal.consyste.com.br.

Todas as comunicações deverão utilizar o formato JSON (preferencialmente) ou XML.

Todas as chamadas devem conter um cabeçalho identificando o usuário que está realizando a operação, através de chave de acesso. Será fornecida uma chave de acesso por usuário.

    X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z`;

export default function Introducao() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}