'use strict';
// @flow

import React from 'react';

import { Label, ProgressBar } from 'react-bootstrap';

import { formatHelper as fmt } from '../../lib/viewUtils';
import ComTooltip from './com_tooltip';

type ProgressoCarregamentoProps = {
  total? : number,
  acertos? : number,
  erros? : number,
  id? : string,
}

export default function ProgressoCarregamento({ total, acertos, erros, id } : ProgressoCarregamentoProps) {
  const hint = <Hint { ...{ total, acertos, erros } }/>;

  return (
      <ComTooltip hint={ hint } posicao="top">
        <ProgressBar>
          <ProgressBar bsStyle="success" max={ total } now={ acertos } key={ `success-${id || ''}` }
                       label={ fmt.numberWithDelimiter(acertos) }/>
          <ProgressBar bsStyle="danger" max={ total } now={ erros } key={ `danger-${id || ''}` }
                       label={ fmt.numberWithDelimiter(erros) }/>
        </ProgressBar>
      </ComTooltip>
  );
}

ProgressoCarregamento.defaultProps = {
  id: 0
};

function Hint({ total, acertos, erros } : ProgressoCarregamentoProps) {
  if (!total)
    return <span>Não iniciado</span>;

  const faltando = total - (acertos || 0) - (erros || 0);

  return (
      <ul className="text-left list-unstyled">
        <HintRow num={ acertos } bsStyle="success"> OK </HintRow>
        <HintRow num={ erros } bsStyle="danger"> com erro </HintRow>
        <HintRow num={ faltando } bsStyle="info"> para concluir </HintRow>
      </ul>
  );
}

function HintRow({ num, bsStyle, children } : { num? : number, bsStyle? : string, children? : any }) {
  if (!num)
    return null;

  return (
      <li>
        <Label bsStyle={ bsStyle } style={ styles.num }>{ fmt.numberWithDelimiter(num) }</Label>
        { children }
      </li>
  );
}

const styles = {
  num: {
    display: 'inline-block',
    textAlign: 'right',
    minWidth: '8em',
    marginRight: '.5em',
  }
};