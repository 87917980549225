// @flow
'use strict';

import * as React from 'react';
import { Button, ButtonToolbar } from 'react-bootstrap';

import { ajaxError, alert } from 'reducers/alerts';
import request from 'lib/request';

type BotoesAcoesRecuperacaoProps = {
  id : string,
  alert : typeof alert,
  ajaxError : typeof ajaxError,
}

export default function BotoesAcoesRecuperacao({ id, alert, ajaxError } : BotoesAcoesRecuperacaoProps) {
  const iniciaRecuperacao = React.useCallback(() => {
    request.get(`/app/ferramentas/recuperacoes/${ id }/iniciar_recuperacao`)
        .accept('json')
        .then(() => alert('info', 'Recuperação iniciada'))
        .catch(e => ajaxError('Erro ao iniciar recuperação. Verifique se você tem permissão para realizar essa operação.', e));
  }, [id, alert, ajaxError]);

  return (
      <ButtonToolbar>
        <Button type="button" className="btn-success" onClick={ iniciaRecuperacao }>
          Iniciar Recuperacao
        </Button>
        <Button type="button" className="btn-warning" disabled>
          Parar Recuperacao
        </Button>
      </ButtonToolbar>
  );
}
