'use strict';
// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import request from 'lib/request';
import { withProvider } from 'lib/reduxTools';
import { ajaxError } from 'reducers/alerts';

type TokenProps = { user_token : ?string, ajaxError : typeof ajaxError };

export function Token({ ajaxError, ...props } : TokenProps) {
  const [userToken, setUserToken] = React.useState(props.user_token || '');

  const gerarToken = React.useCallback((e : SyntheticEvent<*>) => {
    e.preventDefault();
    if (userToken) {
      if (!confirm('Você tem certeza de que quer gerar uma nova chave de acesso?\n\nSua chave antiga será desativada.'))
        return;
    }

    request.post('/app/perfil_usuario/criar_token')
        .accept('json')
        .then(r => setUserToken(r.body.token))
        .catch(e => ajaxError('Erro ao gerar token', e));
  }, [userToken, ajaxError]);

  const excluiChave = React.useCallback((e : SyntheticEvent<*>) => {
    e.preventDefault();
    if (!confirm('Você tem certeza de que quer excluir sua chave de acesso?'))
      return;

    request.delete('/app/perfil_usuario/excluir_token')
        .accept('json')
        .then(r => setUserToken(r.body.token || ''))
        .catch(e => ajaxError('Erro ao excluir token', e));
  }, [ajaxError]);

  let gerarButton, excluirButton;

  if (userToken) {
    excluirButton = <Button bsStyle="danger" onClick={ excluiChave }>Remover</Button>;
    gerarButton = <Button bsStyle="primary" onClick={ gerarToken }>Substituir</Button>;
  }
  else {
    excluirButton = <Button bsStyle="danger" disabled onClick={ excluiChave }>Remover</Button>;
    gerarButton = <Button bsStyle="primary" onClick={ gerarToken }>Gerar</Button>;
  }

  return (
      <div className="inline-form-group-perfil">
        <div className="form-group">
          <label className="control-label">Chave de acesso</label>
          <input className="form-control monospaced-font" size="22" name="token" value={ userToken } readOnly/>
        </div>
        <div className="form-group .inline-form-group-perfil">
          { gerarButton }
          { excluirButton }
        </div>
      </div>
  );
}

export default withProvider()(connect<*, *, *, *, *, *>(null, { ajaxError })(Token));
