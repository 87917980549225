'use strict';
// @flow

import * as React from 'react';

import PainelCustodia from './painel_custodia';
import Situacao from './situacao';

import type { PaineisProps } from './tipos';

/**
 * Monta todos os painéis do módulo de Portaria.
 */
export default function PaineisPortaria(props : PaineisProps) {
  const paineis = [];

  paineis.push(<PainelCustodia { ...props } key="custodia"/>);

  if (props.documento && props.documento.situacao)
    paineis.push(<Situacao { ...props } key="situacao"/>);

  return <div>{ paineis }</div>;
}