'use strict';
// @flow

import React from 'react';

import { autobind } from 'core-decorators';
import { Button } from 'react-bootstrap';

import Icone from 'components/common/icone';

import { uploadNav, uploadPath } from '../upload_path';

type OpcoesProps = { arquivo? : { id? : string } };

export default class Opcoes extends React.PureComponent<OpcoesProps, *> {
  props : OpcoesProps;

  @autobind
  handleDownload(e : SyntheticEvent<*>) {
    e.preventDefault();
    if (this.props.arquivo && this.props.arquivo.id)
      this.onDownload(this.props.arquivo.id);
  }

  onDownload(id : string) {
    if (confirm('Deseja fazer o download do arquivo?'))
      location.href = uploadPath.download(id, { format: 'txt' });
  }

  @autobind
  handleVoltar(e : SyntheticEvent<*>) {
    e.preventDefault();
    uploadNav.listagem();
  }

  render() {
    return (
        <div className="form-actions well well-sm">
          <Button onClick={ this.handleVoltar }>
            <Icone nome="chevron-left" estilo="fas">Voltar</Icone>
          </Button>
          <div className="pull-right">
            <Button bsStyle="primary" onClick={ this.handleDownload }>
              <Icone nome="download" estilo="fas">Download</Icone>
            </Button> { ' ' }
          </div>
        </div>
    );
  }
}
