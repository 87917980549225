'use strict';

export const TIPOS_RECUPERACAO = [
  { value: 'recupere', label: 'Recupere' },
  { value: 'portaria', label: 'Portaria' },
  { value: 'nenhum', label: 'Nenhum' },
];

export const SITUACOES_RECUPERACAO = [
  { value: 'encontrada', label: 'Encontrada' },
  { value: 'recuperar', label: 'Recuperar' },
  { value: 'recuperada', label: 'Recuperada' },
  { value: 'erro', label: 'Erro' },
];
