// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import { rootSelector, aplicaFiltro } from '../../../../reducers/arquivo/upload/filtro';

import Filtros from './filtros';

const mapStateToProps = createStructuredSelector({
  initialValues: rootSelector,
});

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps),
    reduxForm({
      form: 'detalheUploadFiltro', enableReinitialize: true,
      onSubmit(form, dispatch) {
        dispatch(aplicaFiltro(form));
      },
    }),
)(Filtros);
