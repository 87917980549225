'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import RailsErrors from 'rails_errors';
import { autobind } from 'core-decorators';
import { Button, Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Modal, Row } from 'react-bootstrap';

import type { IPoliticaSenha, NomeCampoPoliticaSenha } from '../../../../types';
import { CAMPOS_POLITICA_SENHA } from '../../../../constants';

import BaseComponent from 'lib/base_component';

type ModalFormProps = {
  /** Função que é chamada quando o usuário solicita que a modal seja fechada */
  onDone? : () => void,
  /** Número de usuários que serão afetados pela edição */
  quantidadeUsuarios : number,
  /** Dados da política de senha sendo editada */
  politicaSenha : IPoliticaSenha,

  atualizaPoliticaSenha : (any) => void,
  atualizaStatus : (string) => void,
  status : 'ready' | 'done' | 'sending',
  erro? : Error,
};

export default class ModalForm extends BaseComponent<ModalFormProps, *> {
  props : ModalFormProps;

  nextFocus : ?NomeCampoPoliticaSenha;

  componentDidUpdate() {
    let el : any;
    if (this.nextFocus && (el = ReactDOM.findDOMNode(this.refs[this.nextFocus])))
      el.focus();
    this.nextFocus = undefined;
  }

  @autobind
  close() {
    this.props.atualizaStatus('ready');
    this.props.onDone && this.props.onDone();
  }

  @autobind
  handleSubmit(e : SyntheticEvent<*>) {
    e.preventDefault();
    this.props.atualizaPoliticaSenha(this.getInputValues());
  }

  @autobind
  getInputValues() {
    const form = this.refs['form'];

    return Object.keys(CAMPOS_POLITICA_SENHA).map(k => form.elements[k])
        .reduce((r, el) => _.set(r, el.id, Number(el.value || el.defaultValue || 0)), {});
  }

  render() {
    return (
        // eslint-disable-next-line react/no-string-refs
        <form ref="form">
          <Modal.Header>
            <Modal.Title>Política de senhas</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            { this.renderBody() }
          </Modal.Body>

          <Modal.Footer>
            { this.renderFooter() }
          </Modal.Footer>
        </form>
    );
  }

  renderBody() {
    switch (this.props.status) {
      case 'done':
        return <div>
          A nova política de senha foi aplicada.
        </div>;

      default:
        return <div>
          <Row>
            <Col md={6}> { this.renderNumberInput('validade') } </Col>
            <Col md={6}> { this.renderNumberInput('minusculas') } </Col>
          </Row>
          <Row>
            <Col md={6}> { this.renderNumberInput('tamanho') } </Col>
            <Col md={6}> { this.renderNumberInput('maiusculas') } </Col>
          </Row>
          <Row>
            <Col md={6}> { this.renderNumberInput('unicas') } </Col>
            <Col md={6}> { this.renderNumberInput('algarismos') } </Col>
          </Row>
          <Row>
            <Col md={6} mdOffset={6}> { this.renderNumberInput('simbolos') } </Col>
          </Row>
        </div>;
    }
  }

  renderNumberInput(campo : NomeCampoPoliticaSenha) {
    const { politicaSenha = {} } = this.props,
          { label, help }        = CAMPOS_POLITICA_SENHA[campo];

    // extrai o erro de validação da chave, se houver
    const errors = new RailsErrors(_.get(this.props, 'erro.response.body.errors'));
    const erro = errors.forField(campo);

    if (erro && !this.nextFocus)
      this.nextFocus = campo;

    return (
        <FormGroup controlId={campo} validationState={erro && 'error'}>
          <ControlLabel>{label}</ControlLabel>
          <FormControl ref={campo} defaultValue={politicaSenha[campo]} min="0" type="number"/>
          <HelpBlock>{erro || help}</HelpBlock>
        </FormGroup>
    );
  }

  renderFooter() {
    const btnFechar = <Button onClick={this.close}>Fechar</Button>;

    const ckExpirar = (this.props.quantidadeUsuarios || null) &&
        <FormGroup controlId="expirar" className="pull-left">
          <Checkbox inline>Expirar senha dos {this.props.quantidadeUsuarios} usuários afetados pela
            política?</Checkbox>
        </FormGroup>;

    switch (this.props.status) {
      case 'sending':
        return <div>
          { ckExpirar }
          { btnFechar }
          <Button disabled type="submit" bsStyle="primary">Definindo…</Button>
        </div>;

      case 'ready':
        return <div>
          { ckExpirar }
          { btnFechar }
          <Button bsStyle="primary" type="submit" onClick={this.handleSubmit}>Definir</Button>
        </div>;

      case 'done':
        return <div>
          { btnFechar }
        </div>;
    }
  }
}
