// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as listaUpload from '../../../reducers/arquivo/upload/lista';
import * as modal from '../../../reducers/arquivo/upload/modal';
import { jsSelector } from 'lib/viewUtils';

import CargaUpload from './carga_upload';

const mapStateToProps    = createStructuredSelector({
        uploads: jsSelector(listaUpload.currentPage),
        page: listaUpload.pageSelector,
        open: modal.openModalSelector,
      }),
      mapDispatchToProps = {
        carregaLista: listaUpload.carregaLista,
        abreModal: modal.abreModal,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(CargaUpload);