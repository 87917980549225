'use strict';
// @flow

import React from 'react';
import { Panel } from 'react-bootstrap';

import DefinitionList from 'components/common/definition_list';

import type { ICargaUploadDetalhes } from '../types';

type DadosArquivoProps = {
  arquivo? : 'loading' | 'erro' | ICargaUploadDetalhes,
};

export default function DadosArquivo({ arquivo } : DadosArquivoProps) {
  if (typeof arquivo !== 'object')
    arquivo = {};

  return (
      <Panel className="form-horizontal">
        <Panel.Heading>Dados Gerais</Panel.Heading>
        <Panel.Body>
          <DefinitionList>
            { { label: 'Nome', value: arquivo.nome } }
            { { label: 'Enviado em', value: arquivo.created_at } }
            { { label: 'Enviado por', value: arquivo.usuario } }
            { { label: 'Carregado em', value: arquivo.carregado_em } }
            { { label: 'Situação', value: arquivo.situacao } }
          </DefinitionList>
        </Panel.Body>
      </Panel>
  );
}
