'use strict';
// @flow

import * as React from 'react';

import ListagemItens from './listagem_itens';
import { InputField } from '../../common/input';

type FormularioProps = { decisaoSelecionada : string };

export default function Formulario({ decisaoSelecionada } : FormularioProps) {
  return (
      <section>
        <fieldset>
          <p>
            Informe o motivo de sua decisão, ou qualquer observação que considere relevante:
          </p>
          <InputField name="observacao"/>
          { decisaoSelecionada === 'receber_com_pendencia' && <ListagemItens/> }
        </fieldset>
      </section>
  );
}