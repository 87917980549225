'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.6 Consulta de Download Solicitado

Através dessa funcionalidade, é possível consultar a disponibilidade de download dos arquivos previamente solicitados.

#### Requisição a enviar

    GET /api/v1/download/{id}

#### Parâmetros

Parâmetro     | Conteúdo
------------- | -------------
\`id\`        | identificador recebido ao solicitar o download.

#### Respostas possíveis

##### 200: concluído

Retorna um link para o download dos arquivos, compactados.

\`\`\`
{
  "id": "53d71e27666162088d040000",
  "formato": "xml",
  "tipo_documento": "nfe",
  "atual": 5,
  "total": 5,
  "erros": null,
  "concluido_em": "2014-07-29T01:08:12-03:00",
  "arquivo": "http://download-consyste.s3.amazonaws.com/xmls_2014-07-29T0108.zip"
}
\`\`\`

##### 202: não concluído

Indica que a solicitação ainda não foi concluída. Deverá ser verificado através de nova consulta.

\`\`\`
{
  "id": "53d71e27666162088d040000",
  "formato": "xml",
  "tipo_documento": "nfe",
  "atual": 2,
  "total": 5,
  "erros": null,
  "concluido_em": null,
  "arquivo": null
}
\`\`\`

##### 404: download não encontrado

Significa que o download solicitado não foi encontrado.

\`\`\`
{ "error": "Download não encontrado" }
\`\`\`

#### 500: erro interno do servidor

Ocorreu um erro no processamento. 

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X GET 'https://portal.consyste.com.br/api/v1/download/53eb0ce86661621e4e000000'
\`\`\``;

export default function ConsultaDownload() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}