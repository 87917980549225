'use strict';
// @flow

import * as React from 'react';
import { autobind } from 'core-decorators';
import { Col, Row } from 'react-bootstrap';

import { connectEx, withProvider } from 'lib/reduxTools';

import Form from './form';
import Resultado from './resultado';
import ModalSolicitaXML from './modal_solicita_xml';
import ModalRecuperaXML from './modal_recupera_xml';

import * as consultaPortariaActions from '../../reducers/consultaPortaria';

import type { IActions, IConsulta, IRecuperacao } from './types';

type ConsultaPortariaProps = {
  chaveInicial? : string,
  chave? : string,

  consulta? : ?IConsulta,
  consultaDocumento : { carregando : bool, erro? : { message? : string } },
  consultaSefaz? : { carregando : bool },
  solicitacao : { aberta : bool },
  recuperacao : IRecuperacao,

  actions : IActions,
}

export class ConsultaPortaria extends React.PureComponent<ConsultaPortariaProps, void> {
  props : ConsultaPortariaProps;

  componentDidMount() {
    // se há uma chave inicial, faz uma primeira consulta
    const { chaveInicial } = this.props;
    if (chaveInicial)
      this.props.actions.consultaChave(chaveInicial);
  }

  /* ****************************************************************************** *
   * AÇÕES DO USUÁRIO
   * ****************************************************************************** */

  @autobind
  handleFormSubmit(e : SyntheticEvent<*>) {
    e && e.preventDefault();
    this.props.actions.consultaChave(this.props.chave);
  }

  @autobind
  handleChaveChanged(e : SyntheticInputEvent<*>) {
    this.props.actions.setChave(e.target.value);
  }

  /* ****************************************************************************** *
   * RENDERIZAÇÃO
   * ****************************************************************************** */

  render() {
    const { solicitacao, recuperacao } = this.props;

    return <div>
      <Row>
        <Col md={7} mdOffset={1}>
          <Form {...this.props} onFormSubmit={ this.handleFormSubmit }
                onChaveChanged={ this.handleChaveChanged }/>
        </Col>
      </Row>
      <Resultado {...this.props}/>
      { solicitacao && solicitacao.aberta && <ModalSolicitaXML {...this.props}/> }
      { recuperacao && recuperacao.aberta && <ModalRecuperaXML {...this.props}/> }
    </div>;
  }
}

/**
 * Conecta o componente à store do Redux e às actions.
 */
export default withProvider()(connectEx('consultaPortaria', consultaPortariaActions)(ConsultaPortaria));
