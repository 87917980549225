'use strict';
// @flow

import * as React from 'react';
import { Button } from 'react-bootstrap';

import Icone from 'components/common/icone';

import type { IActions, IConsulta } from './types';

type BotaoProps = {
  consulta : IConsulta,
}

type BotaoComAcoesProps = {
  consulta : IConsulta,
  actions : IActions,
}

/**
 * Botão "Recuperar XML"
 *
 * Abre a modal de recuperação.
 */
export function BotaoRecuperar({ consulta, actions: { abreRecuperacao } } : BotaoComAcoesProps) {
  return (
      <Button block onClick={ abreRecuperacao } disabled={ !consulta.permite_recuperar_xml }>
        <Icone nome="cloud-download" estilo="fas"> Recuperar XML</Icone>
      </Button>
  );
}

/**
 * Botão "Download XML"
 *
 * Um link para a ação de download.
 */
export function BotaoDownload({ consulta } : BotaoProps) {
  return (
      <Button bsSize="sm" href={ `${ consulta.url }/download_xml` } disabled={ !consulta.permite_download_xml }>
        <Icone nome="cloud-download" estilo="fas">Download XML</Icone>
      </Button>
  );
}

/**
 * Botão "Imprimir PDF"
 *
 * Um link para a ação de impressão.
 */
export function BotaoImprimir({ consulta } : BotaoProps) {
  return (
      <Button bsSize="sm" href={ `${ consulta.url }/download_pdf` } disabled={ !consulta.permite_imprimir_pdf }>
        <Icone nome="print" estilo="fas"> Imprimir PDF</Icone>
      </Button>
  );
}

/**
 * Botão "Mais Informações"
 *
 * Um link para a visualização do documento.
 */
export function BotaoMaisInformacoes({ consulta } : BotaoProps) {
  if (!consulta.permite_ver_mais_informacoes || !consulta.documento || !consulta.documento.url)
    return null;

  return (
      <Button bsSize="sm" href={ consulta.documento.url }>
        <Icone nome="eye" estilo="far"> Mais Informações</Icone>
      </Button>
  );
}