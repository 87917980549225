'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.9 Emissão de NF-e

<p style="margin-left: 5px;">Realiza a emissão de uma NF-e através de um TXT, podendo também consultar o status de uma emissão já solicitada.</p>

#### 3.9.1 Envio de dados para emissão de NFe

O cliente envia um TXT, contendo os dados para emissão de uma NFe, e o portal retorna o ID da NFe ao cliente.

#### Requisição a enviar

Para solicitar a emissão de uma Nfe

    POST /api/v1/emissao/{amb}/nfe?cnpj={cnpj}

#### Parâmetros

Parâmetro     | Conteúdo
------------- | -------------
\`amb\`       | Ambiente em que a emissão será solicitada (1 - Produção e 2 - Homologação).
\`cnpj\`      | CNPJ do emitente da NFe.

#### Exemplo

     POST /api/v1/emissao/2/nfe?cnpj=88309136000129

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z'
     -H 'Content-Type: text/plain'
     -X POST 'https://portal.consyste.com.br/api/v1/emissao/2/nfe?cnpj=88309136000129'
\`\`\`     

#### Corpo da requisição

\`\`\`
NOTAFISCAL|1
A|3.10||
B|35||VENDA|2|55|1|1126|2020-09-21T00:00:00-03:00|2020-09-22T00:00:00-03:00|1|1|3550308|1|1|||1|0|0|1|3.10.35|||
...
\`\`\`

#### Respostas possíveis

##### 200: NFe criada

Retorna o ID da NFe e os resultados das validações.

\`\`\`
{
  "nfe_id": "587e3c3d6f8ea7710f8fcf74",
  "resultado_validacoes": "['Emissão já foi solicitada.']"
}
\`\`\`

##### 202: Solicitação de emissão não foi concluída

Significa que ocorreu um erro na emissão e retorna o alerta gerado.

\`\`\`
{ "alerta": "No document found for query: { _id: '62c73ccbc8d149000179b0d7' }" }
\`\`\`

##### 422: requisição inválida

Significa que a requisição não possui o formato especificado.

\`\`\`
{ "error": "Requisição não atende ao formato especificado" }
\`\`\`

##### 422: CNPJ inválido

Significa que o CNPJ informado não é válido.

\`\`\`
{ "error": "CNPJ informado não é válido" }
\`\`\`

##### 403: CNPJ inexistente ou inativo na Consyste

Significa que o CNPJ informado é de uma empresa que não está cadastrada na Consyste ou está inativa.

\`\`\`
{ "error": "CNPJ inexistente ou inativo na Consyste" }
\`\`\`

##### 403: CNPJ sem premissão

Significa que o CNPJ informado não é de uma empresa que o usuário tem permissão.

\`\`\`
{ "error": "CNPJ não faz parte da sua conta" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Banco de dados fora do ar" }
\`\`\``;

export default function EmissaoNfe() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}
