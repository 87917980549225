'use strict';

import $ from 'jquery';

$(function() {
  const m = $('#decisao-portaria');

  // curto-circuito, caso não estejamos na página da portaria
  if (m.length === 0)
    return;

  m.on('shown.bs.modal', function() {
    $('textarea', m).focus();
  });

  $('*[data-decisao]').click(function(e) {
    e.preventDefault();
    const $btn = $(this),
          code = $btn.data('decisao');

    $('#codigo-decisao-portaria', m).val(code);
    $('h4', m).hide();
    $(`h4#for-code-${code}`, m).show();
    m.modal('show');
  });
});
