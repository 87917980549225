'use strict';
// @flow

export type IEmail = {
  id : string,
  caixa? : string,
  emitente? : string,
  assunto? : string,
  situacao? : {
    codigo : 'novo' | 'processado' | 'ignorado' | 'erro',
  },
  created_at : string,
}

export const SITUACOES = [
  { value: 'novo', label: 'Novo' },
  { value: 'processado', label: 'Processado' },
  { value: 'ignorado', label: 'Ignorado' },
  { value: 'erro', label: 'Com erros' }
];
