'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.1 Envio de Documentos

Envia um XML individual para custódia. Pode ser utilizado para enviar NF-e ou CT-e. Tanto podem ser enviados documentos relacionados ao CNPJ quanto documentos de terceiros.

#### Requisição a enviar

Para enviar um arquivo individual:

    POST /api/v1/envio

#### Corpo da requisição

\`\`\`
{
    "xml": "<procNFe ...",
    "terceiro_cnpj": "000000000"
}
\`\`\`

Parâmetro         | Conteúdo                                               | Opcional
----------------- | -------------                                          | ------------
\`xml\`           | O conteúdo XML do novo documento.                      | não
\`terceiro_cnpj\` | O CNPJ do terceiro que poderá ter acesso ao documento. | sim

#### Respostas possíveis

##### 201: documento criado

\`\`\`
{
  "documento": {
    "id": "53d2f08f9711f6abe20009e7",
    "chave": "35140443283811001040550010001000151082422890",
    "emitido_em": "2014-04-08T00:00:00-03:00",
    "serie": 1,
    "numero": 100015,
    "valor": "111.4",
    "situacao_custodia": "ok",
    "situacao_sefaz": 100,
    "emit_cnpj": "43283811001040",
    "emit_nome": "Kalunga Comercio Industria Grafica Ltda",
    "dest_cnpj": "10967573000142",
    "dest_nome": "MEp Consultoria"
  }
}
\`\`\`

##### 422: parâmetros inválidos

Significa que a requisição não foi aceita por estar estruturalmente incorreta. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "CNPJ informado não é válido" }
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita por possuir dados inválidos. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "XML não é válido" }
\`\`\`

##### 403: ação não permitida

Significa que sua chave de acesso não tem permissão para criar o documento.

\`\`\`
{ "error": "CNPJ não faz parte da sua conta" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Validação de XML fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X POST 'https://portal.consyste.com.br/api/v1/nfe/lista/recebidos'
     -F xml=@arquivo.xml
     -F terceiro_cnpj=12345678000123
\`\`\``;

export default function EnvioDocumentos() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}