// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { itensSelector } from '../../../../reducers/decisaoMercadoria';

import ListagemItens from './listagem_itens';

const mapStateToProps = createStructuredSelector({ itens: itensSelector });

export default connect<*, *, *, *, *, *>(mapStateToProps)(ListagemItens);