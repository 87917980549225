// @flow
'use strict';

import * as React from 'react';
import ReactSelect from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ControlLabel, FormControl, FormGroup, Navbar } from 'react-bootstrap';

import { jsSelector, useEffectOnce } from '../../../lib/viewUtils';
import { mudaFiltro, inicializaFiltros, filtroSelector } from '../../../reducers/recuperacao/filtro';
import { contasParaFiltro, carregaContas } from '../../../reducers/admin/contas';
import type { FiltrosRecuperacao } from '../types';
import { TIPOS_RECUPERACAO, SITUACOES_RECUPERACAO } from '../constants';

import Icone from '../../common/icone';
import DatePicker from '../../common/date_picker';

type FiltrosProps = {
  filtrosIniciais : FiltrosRecuperacao,
};

export default function Filtros({ filtrosIniciais } : FiltrosProps) {
  const filtrosJsSelector = React.useMemo(() => jsSelector(filtroSelector, []), []),
        contas            = useSelector(contasParaFiltro),
        filtros           = useSelector(filtrosJsSelector);

  const { q, tipo, conta_id, data_inclusao_de, data_inclusao_ate, chave, situacao } = filtros || {};

  const dispatch        = useDispatch(),
        handleSetFor    = React.useCallback((k) => v => dispatch(mudaFiltro(k, v)), [dispatch]),
        handleChangeFor = React.useCallback((k) => e => dispatch(mudaFiltro(k, e.target.value)), [dispatch]),
        reiniciaFiltro  = React.useCallback(() => window.location.href = '/app/ferramentas/recuperacoes', []);

  useEffectOnce(() => {
    if (filtrosIniciais.q === null)
      filtrosIniciais.tipo = 'recupere';

    dispatch(inicializaFiltros(filtrosIniciais));
    dispatch(carregaContas());
  });

  return (
      <form method="get" role="search">
        <Navbar fluid className="sped-busca-basica">
          <Navbar.Header><Navbar.Brand>Filtros</Navbar.Brand></Navbar.Header>

          <Navbar.Form className="navbar-left">
            <FormGroup controlId="nome">
              <FormControl name="q" placeholder="Pesquisa rápida" type="text" value={ q || '' }
                           style={ { width: '18em' } } onChange={ handleChangeFor('q') }/>
            </FormGroup> { ' ' }
            <FormGroup>
              <ControlLabel srOnly>Tipo</ControlLabel>
              <ReactSelect name="tipo" options={ TIPOS_RECUPERACAO } placeholder="Tipo" value={ tipo }
                           style={ { width: '9em' } } simpleValue onChange={ handleSetFor('tipo') }/>
            </FormGroup> { ' ' }
            <FormGroup controlId="contaId">
              <ControlLabel srOnly>Conta</ControlLabel>
              <ReactSelect name="conta_id" options={ contas } placeholder="Conta" value={ conta_id }
                           style={ { width: '18em' } } simpleValue
                           onChange={ handleSetFor('conta_id') }/>
            </FormGroup> { ' ' }
            <FormGroup>
              <ControlLabel srOnly>Data de inclusão de</ControlLabel>
              <DatePicker name="data_inclusao_de" value={ data_inclusao_de } placeholder="Data de inclusão de"
                          width="11em"
                          onChange={ handleSetFor('data_inclusao_de') }/>
            </FormGroup> { ' ' }
            <FormGroup>
              <ControlLabel srOnly>Data de inclusão até</ControlLabel>
              <DatePicker name="data_inclusao_ate" value={ data_inclusao_ate } placeholder="Data de inclusão até"
                          width="12em"
                          onChange={ handleSetFor('data_inclusao_ate') }/>
            </FormGroup> { ' ' }
            <FormGroup>
              <FormControl name="chave" placeholder="Chave" type="text" value={ chave || '' }
                           style={ { width: '27.25em' } } onChange={ handleChangeFor('chave') }/>
            </FormGroup> { ' ' }
            <FormGroup>
              <ReactSelect name="situacao" options={ SITUACOES_RECUPERACAO } placeholder="Situação" value={ situacao }
                           style={ { width: '18em' } } simpleValue onChange={ handleSetFor('situacao') }/>
            </FormGroup>
          </Navbar.Form>

          <Navbar.Form className="navbar-right">
            <div className="pull-right">
              <Button type="reset" onClick={ reiniciaFiltro }>
                <Icone nome="eraser" estilo="fas">Limpar</Icone>
              </Button> { ' ' }
              <Button type="submit" style={ { marginRight: '-5px' } } bsStyle="primary">
                <Icone nome="filter" estilo="far">Filtrar</Icone>
              </Button>
            </div>
          </Navbar.Form>
        </Navbar>
      </form>
  );
}
