// @flow
'use strict';

import React from 'react';

import Icone from 'components/common/icone';

type IconeSituacaoProps = {
  situacao? : {
    codigo : 'novo' | 'processado' | 'ignorado' | 'erro'
  }
};

export default function IconeSituacao({ situacao } : IconeSituacaoProps) {
  switch (situacao && situacao.codigo) {
    case 'novo':
      return <Icone nome="clock" estilo="far" className="text-muted"/>;
    case 'processado':
      return <Icone nome="check" estilo="fas" className="text-success"/>;
    case 'ignorado':
      return <Icone nome="trash-alt" estilo="fas" className="text-warning"/>;
    case 'erro':
      return <Icone nome="bug" estilo="fas" className="text-danger"/>;
    default:
      return null;
  }
}
