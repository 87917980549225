'use strict';
// @flow

import * as Immutable from 'immutable';
import React from 'react';
import { Table } from 'react-bootstrap';
import { FormSection } from 'redux-form/immutable';

import type { IItem } from '../../types';

import { Input } from '../../../common/input';

type DadosTabela = Immutable.List<*, *>;

type TabelaProps = {
  dados : ?DadosTabela
}

type IRow = IItem & {
  posicao : number
}

export default class Tabela extends React.PureComponent<TabelaProps, *> {
  props : TabelaProps;

  render() {
    return (
        <Table condensed striped hover className="tabela-checkbox">
          <colgroup>
            <col width={ 1 }/>
            <col/>
          </colgroup>
          <thead>
          <tr>
            <th><span/></th>
            <th className="text-left"><span>Item</span></th>
          </tr>
          </thead>
          <tbody>
          { this.renderRows(this.props.dados, 2) }
          </tbody>
        </Table>
    );
  }

  renderRows(dados : ?DadosTabela, cols : number) {
    if (!dados || dados.size === 0) {
      return <tr className="nodata">
        <td colSpan={ cols }>Nenhum item encontrado.</td>
      </tr>;
    }
    else if (dados.size > 0)
      return dados.map((d) => <TableRow key={ d.posicao } row={ d }/>);
    else
      throw new Error(`Dados inválidos: ${ JSON.stringify(dados) }`);
  }
}

/**
 * Uma linha da tabela de documentos.
 */
export function TableRow({ row } : { row : IRow }) {
  return (
      <tr>
        <td>
          <FormSection name={ `itens.${ row.posicao }` }>
            <Input name="pendente" label="" checkbox/>
          </FormSection>
        </td>
        <td className="text-left">
          <strong>{ row.codigo }</strong>
          <span>{ descricao(row.descricao) } </span>
          <em className="pedido">{ pedido(row.pedido_nro, row.pedido_item) }</em>
        </td>
      </tr>
  );
}

function descricao(descr : ?string) {
  return descr ? ` ${descr}` : '';
}

function pedido(numero : ?string, item : ?number) {
  if (!numero && !item)
    return '';

  const strNum  = numero ? `pedido ${numero}` : '',
        strItem = item ? ` item ${item}` : '';

  return ` (${ strNum }${ strItem })`;
}
