'use strict';

// @flow

import * as React from 'react';
import { autobind } from 'core-decorators';

import { formatHelper as fmt } from 'lib/viewUtils';
import { connectEx } from 'lib/reduxTools';

import * as emailsActions from '../../../reducers/emails';

import IconeSituacao from './icone_situacao';
import type { IEmail } from '../constants';

type LinhaTabelaProps = {
  email : IEmail,
  actions : {
    detalhes : (id : string) => void
  }
}

class LinhaTabela extends React.PureComponent<LinhaTabelaProps, void> {
  props : LinhaTabelaProps;

  @autobind
  handleClick() {
    this.props.actions.detalhes(this.props.email.id);
  }

  render() {
    const { email } = this.props;

    return <tr className="cursor-pointer" onClick={ this.handleClick }>
      <td><IconeSituacao situacao={ email.situacao }/></td>
      <td>{ email.caixa }</td>
      <td>{ email.emitente }</td>
      <td>{ email.assunto || '(sem assunto)' }</td>
      <td>{ fmt.formatDateTime(email.created_at) }</td>
    </tr>;
  }
}

export default connectEx(null, emailsActions)(LinhaTabela);