'use strict';
// @flow

import React from 'react';
import moment from 'moment';
import { autobind } from 'core-decorators';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import Icone from 'components/common/icone';
import { formatHelper as fmt } from 'lib/viewUtils';

import { uploadNav } from '../upload_path';
import type { ICargaUpload } from '../types';

type IDados = 'loading' | 'erro' | ICargaUpload[];

type TabelaCargaUploadProps = {
  suporte? : bool,
  dados? : IDados,
};

export default class TabelaCargaUpload extends React.PureComponent<TabelaCargaUploadProps, *> {
  props : TabelaCargaUploadProps;

  render() {
    return (
        <div>
          <Table striped>
            <colgroup>
              <col/>
              <col/>
              <col width={ 80 }/>
              { this.props.suporte && <col/> }
              <col width={ 110 }/>
              <col width={ 100 }/>
            </colgroup>
            <thead>
            <tr>
              <th>Nome do arquivo</th>
              <th>Descrição</th>
              <th>Tamanho</th>
              { this.props.suporte && <th>Usuário</th> }
              <th>Data de envio</th>
              <th>Situação</th>
            </tr>
            </thead>
            <tbody>
            { this.renderRows(this.props.dados, 5 + (this.props.suporte ? 1 : 0)) }
            </tbody>
          </Table>
        </div>
    );
  }

  renderRows(dados? : IDados, cols : number) {
    if (!dados) {
      return <tr className="nodata">
        <td colSpan={ cols }>Nenhum upload efetuado.</td>
      </tr>;
    }
    else if (dados === 'loading') {
      return <tr className="nodata">
        <td colSpan={ cols }><Icone nome="sync-alt" estilo="fas" tamanho={2} spin /></td>
      </tr>;
    }
    else if (dados === 'erro') {
      return <tr className="nodata">
        <td colSpan={ cols }>Ocorreu um erro ao carregar a lista de uploads.</td>
      </tr>;
    }
    else if (dados.length === 0) {
      return <tr className="nodata">
        <td colSpan={ cols }>Nenhum registro encontrado.</td>
      </tr>;
    }
    else
      return <> { dados.map(d => <Linha { ...d } key={ d.id }/>) } </>;
  }
}

type LinhaProps = ICargaUpload;

export class Linha extends React.PureComponent<LinhaProps, *> {
  props : LinhaProps;

  @autobind
  handleRowClick(e : SyntheticEvent<*>) {
    e.preventDefault();
    if (this.props.id)
      uploadNav.detalhes(this.props.id);
  }

  @autobind
  handleUserClick(e : SyntheticEvent<*>) {
    e.stopPropagation();
  }

  render() {
    let desc = this.props.situacao && this.props.situacao.label;
    if (this.props.carregado_em) {
      const tip = <Tooltip id={ `tcu-tooltip-${ this.props.id || 'x' }` }>
        Carga realizada em { moment(this.props.carregado_em).format('L, HH:mm:ss') }
      </Tooltip>;
      desc = <OverlayTrigger placement="bottom" overlay={ tip }><span>{ desc }</span></OverlayTrigger>;
    }

    return (
        <tr key={ this.props.id } className="cursor-pointer" onClick={ this.handleRowClick }>
          <td>{ this.props.arquivo.nome }</td>
          <td>{ this.props.descricao }</td>
          <td className="c">{ fmt.formatSize(this.props.arquivo.tamanho) }</td>
          { this.props.usuario && <td> { this.props.usuario.url ?
              <a href={ this.props.usuario.url }
                 onClick={ this.handleUserClick }>{ this.props.usuario.nome }</a> : '–' }</td> }
          <td className="c">{ moment(this.props.created_at).format('DD/MM/YYYY') }</td>
          <td>{ desc }</td>
        </tr>
    );
  }
}
