// @flow

export const CAMPOS_POLITICA_SENHA = {
  validade: { label: 'Validade', help: 'validade máxima de uma senha, em dias' },
  tamanho: { label: 'Tamanho', help: 'tamanho mínimo de caracteres que uma senha deve conter' },
  minusculas: { label: 'Minúsculas', help: 'número mínimo de letras minúsculas que uma senha deve conter' },
  maiusculas: { label: 'Maiúsculas', help: 'número mínimo de letras maiúsculas que uma senha deve conter' },
  simbolos: { label: 'Símbolos', help: 'número mínimo de símbolos que uma senha deve conter' },
  algarismos: { label: 'Algarismos', help: 'número mínimo de algarismos numéricos que uma senha deve conter' },
  unicas: {
    label: 'Únicas',
    help: 'quantidade de senhas únicas que o usuário precisa alterar antes de repetir uma senha'
  },
};
