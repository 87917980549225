'use strict';
// @flow

import React from 'react';

import type { ICargaUploadDetalhes } from '../types';

import PaginationFooter from 'components/common/pagination_footer';
import TableComponent from 'components/uploads/detalhes/table_component';

type ListaArquivosProps = {
  arquivo? : ICargaUploadDetalhes,
  carregaDetalhes : (id : string, params : any) => void;
}

export default function ListaArquivos({ arquivo, carregaDetalhes } : ListaArquivosProps) {
  const handlePageChange = React.useCallback((page : number) => {
    if (arquivo && arquivo.id)
      carregaDetalhes(arquivo.id, { page });
  }, [arquivo, carregaDetalhes]);

  const { lista_arquivos }               = arquivo || {},
        { items, page, total, pageSize } = lista_arquivos || {};

  return (
      <fieldset>
        <TableComponent dados={ items }/>
        <PaginationFooter label="Arquivos" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ handlePageChange }/>
      </fieldset>
  );
}
