'use strict';
// @flow

import React from 'react';

import type { IPaginatedData } from 'lib/types';
import { useEffectOnce } from 'lib/viewUtils';

import TabelaDownloads from './tabela_downloads';
import PaginationFooter from 'components/common/pagination_footer';

type DownloadsProps = {
  page? : number,
  downloads? : IPaginatedData<any>,
  carregaLista : (page : ?number) => void,
  alteraAtualizaTela : (bool) => void,
}

export default function Downloads({ carregaLista, alteraAtualizaTela, page: requestedPage, downloads } : DownloadsProps) {
  useEffectOnce(() => {
    carregaLista(requestedPage);

    return () => alteraAtualizaTela(false);
  });

  const handlePagination = React.useCallback((page : number) => {
    carregaLista(page);
  }, [carregaLista]);

  const { page, pageSize, total, items } = typeof downloads === 'object' ? downloads : {};

  return (
      <div>
        <TabelaDownloads dados={ items }/>
        <PaginationFooter label="Downloads" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ handlePagination }/>
      </div>
  );
}