'use strict';

// @flow

import React from 'react';
import { Table } from 'react-bootstrap';

import Icone from 'components/common/icone';
import Contador from 'components/common/contador';

import LinhaTabela from './linha_tabela';

type TabelaEmailsProps = {
  dados? : any,
  carregando : bool,
  total? : number, // Quantidade de registros encontrados
};

export default function TabelaEmails({ dados, carregando, total } : TabelaEmailsProps) {
  return (
      <div>
        <Contador total={ total } prefix="E-mails encontrados:"/>
        <Table striped hover>
          <colgroup>
            <col width={ 1 }/>
            <col width={ 35 }/>
            <col width={ 35 }/>
            <col/>
            <col width={ 140 }/>
          </colgroup>
          <thead>
          <tr>
            <th/>
            <th>Caixa</th>
            <th>De</th>
            <th>Assunto</th>
            <th>Recebido em</th>
          </tr>
          </thead>
          <CorpoTabela carregando={ carregando } dados={ dados } cols={ 5 }/>
        </Table>
      </div>
  );
}

type CorpoTabelaProps = {
  // Registros da tabela, ou texto informando situação atual dos dados (loading, erro e nodata)
  dados? : 'nodata' | 'loading' | 'erro' | Array<any>,
  carregando? : bool;

  // Número total de colunas
  cols : number
};

function CorpoTabela({ carregando, dados, cols } : CorpoTabelaProps) {
  let rows;

  if (carregando) {
    return (
        <tbody>
        <tr className="nodata">
          <td colSpan={ cols }>Aguarde, procurando e-mails...</td>
        </tr>
        </tbody>
    );
  }

  if (!dados) {
    rows = <tr className="nodata">
      <td colSpan={ cols }>Nenhum E-mail encontrado.</td>
    </tr>;
  }
  else if (dados === 'loading') {
    rows = <tr className="nodata">
      <td colSpan={ cols }><Icone nome="sync-alt" estilo="fas" tamanho={2} spin /></td>
    </tr>;
  }
  else if (dados === 'erro') {
    rows = <tr className="nodata">
      <td colSpan={ cols }>Ocorreu um erro ao carregar a lista de emails.</td>
    </tr>;
  }
  else if (dados.length === 0) {
    rows = <tr className="nodata">
      <td colSpan={ cols }>Nenhum registro encontrado.</td>
    </tr>;
  }
  else if (Array.isArray(dados))
    rows = dados.map(e => <LinhaTabela key={ e.id } email={ e }/>);
  else
    throw new Error(`Dados inválidos: ${ JSON.stringify(dados) }`);

  return <tbody>{ rows }</tbody>;
}
