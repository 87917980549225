'use strict';
// @flow

import React from 'react';
import { Button } from 'react-bootstrap';

import type { IPaginatedData } from 'lib/types';

import Icone from 'components/common/icone';
import PaginationFooter from 'components/common/pagination_footer';
import ModalCargaUpload from './modal_carga_upload';
import TabelaCargaUpload from './tabela_carga_upload';
import { useEffectOnce } from '../../../lib/viewUtils';

type CargaUploadProps = {
  /** Número da página atual */
  page : ?number,
  /** Lista de uploads */
  uploads? : IPaginatedData<any> & { suporte? : bool };
  carregaLista : (page : ?number) => void,
  open : bool,
  abreModal : () => void,
};

export default function CargaUpload({ uploads, abreModal, carregaLista, open, ...props } : CargaUploadProps) {
  const handleNovoUploadClick = React.useCallback(() => abreModal(), [abreModal]),
        handlePagination      = React.useCallback((page : ?number) => carregaLista(page), [carregaLista]);

  useEffectOnce(() => {
    carregaLista(props.page);
    handlePagination(props.page);
  });

  const { page, total, items, pageSize, suporte } = typeof uploads === 'object' ? uploads : {};

  return (
      <div>
        <div className="well well-sm clearfix">
          <div className="pull-right">
            <Button bsStyle="primary" onClick={ handleNovoUploadClick } className="pull-right">
              <Icone nome="cloud-upload-alt" estilo="fas">Novo Upload</Icone>
            </Button>

            { open && <ModalCargaUpload/> }
          </div>
        </div>
        <TabelaCargaUpload dados={ items } suporte={ suporte }/>
        <PaginationFooter label="Uploads" page={ page } total={ total } pageSize={ pageSize }
                          onPageChange={ handlePagination }/>
      </div>
  );
}
