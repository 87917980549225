// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { carregaDetalhes, carregandoSelector, itemSelector } from '../../../reducers/arquivo/upload/detalhes';
import { alert } from '../../../reducers/alerts';
import { jsSelector } from 'lib/viewUtils';

import DetalhesUpload from './detalhes_upload';

const mapStateToProps = createStructuredSelector({ item: jsSelector(itemSelector), carregando: carregandoSelector });

export default connect<*, *, *, *, *, *>(mapStateToProps, { carregaDetalhes, alert })(DetalhesUpload);