'use strict';
// @flow

import * as React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';

import type { IDecisao } from '../types';
import Formulario from './formulario';

type ModalDecisaoPortariaProps = {
  decisao : IDecisao,
  handleSubmit : () => void,
  fechaDecisao : () => void,
};

export default function ModalDecisaoPortaria({ decisao, handleSubmit, fechaDecisao } : ModalDecisaoPortariaProps) {
  const { erro, opcaoSelecionada, escolhendo, enviando } = decisao,
        { titulo }                                       = opcaoSelecionada;

  const alertaErro = erro && <Alert bsStyle="danger">{ erro }</Alert>;

  return (
      <Modal bsSize="large" show={ escolhendo } onHide={ fechaDecisao }>
        <Modal.Header closeButton>
          <Modal.Title>{ titulo }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formulario decisaoSelecionada={ opcaoSelecionada.decisao }/>
          { alertaErro }
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ fechaDecisao } disabled={ enviando }> Cancelar </Button>
          <Button bsStyle="primary" onClick={ handleSubmit } disabled={ enviando }>
            { opcaoSelecionada.decisao === 'receber_com_pendencia' ? 'Informar Pendência' : 'Confirmar' }
          </Button>
        </Modal.Footer>
      </Modal>
  );
}
