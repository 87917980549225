'use strict';

import $ from 'jquery';

$(function() {
  const $adicionar_empresa_ou_usuario = $('#add-empresa, #add-user');

  if ($adicionar_empresa_ou_usuario.length === 0)
    return;

  $adicionar_empresa_ou_usuario
      .on('click', '.pagination a', function(e) {
        e.preventDefault();
        const $link = $(this);
        $('.placeholder', $link.closest('.modal')).load($link.attr('href'));
      })
      .on('submit', "form[role='search-form']", function(e) {
        e.preventDefault();
        const $frm = $(this);
        $.ajax({ type: $frm.attr('method'), url: $frm.attr('action'), data: $frm.serialize() })
            .done(data => $('.placeholder', $frm.parent()).html(data).trigger('ajax-loaded'));
      })
      .on('click', "button[role='add']", function(e) {
        e.preventDefault();
        $("form:last", $(this).closest('.modal')).submit();
      });
});
