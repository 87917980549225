'use strict';
// @flow

import * as _ from 'lodash';
import * as React from 'react';

import AlertWithIcon from 'components/common/alert_with_icon';

import { TEXTO_POR_SITUACAO } from 'i18n/portaria.pt';

import type { IDocumento } from 'lib/api';

type AlertaAvaliacaoProps = {
  consulta : {
    aceite_recomendado : bool,
    documento : ?IDocumento
  }
};

export default function AlertaAvaliacao({ consulta } : AlertaAvaliacaoProps) {
  const ud = _.get(consulta, 'documento.portaria.ultimaDecisao');
  let alertProps;

  if (ud) {
    const md = TEXTO_POR_SITUACAO['already_decided'].replace(/%\{decision}/, ud.label);
    alertProps = { bsStyle: 'info', icon: 'info-circle', markdown: md };
  }
  else if (consulta.aceite_recomendado)
    alertProps = { bsStyle: 'success', icon: 'check', markdown: TEXTO_POR_SITUACAO['ok'] };
  else
    alertProps = { bsStyle: 'danger', icon: 'exclamation-triangle', markdown: TEXTO_POR_SITUACAO['not_ok'] };

  return <AlertWithIcon {...alertProps } />;
}