'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import ReactSelect from 'react-select';
import Loader from 'react-loader-advanced';

import { autobind } from 'core-decorators';

import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';

import BaseComponent from 'lib/base_component';
import { connectEx } from 'lib/reduxTools';
import { formatHelper as fmt } from 'lib/viewUtils';

import { SITUACOES } from '../constants';

import * as emailsActions from '../../../reducers/emails';
import Icone from 'components/common/icone';

const EMPTY_STATE = { caixa: undefined, situacao: undefined, busca: '' };

const LOADER_OPTIONS = {
  message: 'Carregando opções...', foregroundStyle: { color: 'black', textAlign: 'left', padding: '10px' },
  backgroundStyle: { backgroundColor: 'white', opacity: 0.4 },
};

type FiltrosProps = {
  carregando : bool,
  caixas : Array<any>,       // Nomes e quantidades das caixas de e-mails
  location : { query : any }, // URL atual
  actions : {
    listagem : (params : {}) => void
  }
};

type IOption = { label : string, value : string };

type FiltrosState = {
  caixa : ?(string | IOption),
  situacao : ?(string | IOption),
  busca : string,
  caixas? : IOption[],
  caixasFromProps? : any,
};

export class Filtros extends BaseComponent<FiltrosProps, FiltrosState> {
  props : FiltrosProps;
  state : FiltrosState = EMPTY_STATE;

  componentDidMount() {
    const { caixa, situacao, busca } = _.get(this.props, 'location.query') || {};
    this.setState({
      caixa: _.find(this.props.caixas, { value: caixa }) || caixa,
      situacao: _.find(SITUACOES, { value: situacao }),
      busca: busca || '',
    });
  }

  static getDerivedStateFromProps(nextProps : FiltrosProps, { caixa, caixasFromProps } : FiltrosState) {
    if (nextProps.caixas !== caixasFromProps) {
      // se recebemos uma nova lista de caixas, atualiza a lista de caixas para o state
      const caixas = (nextProps.caixas : any).map(c => ({ ...c, label: c._id, value: c._id }));

      // se a caixa que temos no state é do tipo string, significa que veio dos parâmetros antes da lista de caixas.
      // neste caso, localiza a seleção inicial e coloca no state, para que a <select> venha preenchida.
      if (typeof caixa === 'string')
        caixa = _.find(caixas, { value: caixa });

      return { caixasFromProps, caixas, caixa };
    }
  }

  @autobind
  handleFormSubmit(e? : SyntheticEvent<*>) {
    e && e.preventDefault();

    const { caixa, situacao, busca } = this.state;

    this.props.actions.listagem({
      caixa: caixa && typeof caixa === 'object' ? caixa.value : caixa,
      situacao: situacao && typeof situacao === 'object' ? situacao.value : situacao,
      busca,
      page: 1,
    });
  }

  @autobind
  handleReset(e? : SyntheticEvent<*>) {
    e && e.preventDefault();

    this.setState(EMPTY_STATE, () => this.handleFormSubmit());
  }

  render() {
    const { situacao, caixa, busca, caixas } = this.state;
    const { carregando } = this.props;

    return (
        <form method="post" role="search" onSubmit={ this.handleFormSubmit }>
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header"><a className="navbar-brand">Filtros</a></div>
              <div className="navbar-form navbar-left">
                <FormGroup controlId="situacao">
                  <ControlLabel srOnly>Situação</ControlLabel>
                  <ReactSelect options={ SITUACOES } placeholder="Situação" value={ situacao } simpleValue
                               style={ { width: '10em' } }
                               onChange={ this.handleSelectFor('situacao') }/>
                </FormGroup> { ' ' }
                <FormGroup controlId="caixa">
                  <ControlLabel srOnly>Endereço / Caixa</ControlLabel>
                  <Loader { ...LOADER_OPTIONS } show={ carregando }>
                    <ReactSelect options={ caixas } placeholder={ carregando ? '' : 'Caixa' } value={ carregando ? '' : caixa }
                                 simpleValue style={ { width: '20em' } } optionRenderer={ this.caixaRenderer }
                                 onChange={ this.handleSelectFor('caixa') }/>
                  </Loader>
                </FormGroup> { ' ' }
                <FormGroup controlId="filtroBusca" style={ { width: '18em' } }>
                  <ControlLabel srOnly>Busca rápida</ControlLabel>
                  <FormControl type="text" placeholder="Busca rápida" value={ busca }
                               onChange={ this.handleChangeFor('busca') }/>
                </FormGroup>
              </div>
              <div className="navbar-form navbar-right">
                <Button type="reset" bsStyle="default" onClick={ this.handleReset }>
                  <Icone nome="eraser" estilo="fas">Limpar</Icone>
                </Button> { ' ' }
                <Button type="submit" bsStyle="primary">
                  <Icone nome="filter" estilo="far">Filtrar</Icone>
                </Button>
              </div>
            </div>
          </nav>
        </form>
    );
  }

  caixaRenderer({ _id, count } : { _id : string, count : number }) {
    return (
        <small>
          <nobr>
            { _id || <span className="text-muted">(sem caixa)</span> }{ ' ' }
            (<b>{ fmt.numberWithDelimiter(count) }</b>)
          </nobr>
        </small>
    );
  }
}

export default connectEx(null, emailsActions)(Filtros);
