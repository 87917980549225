// @flow
'use strict';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import * as modal from '../../../../reducers/arquivo/upload/modal';

import ModalCargaUpload from './modal_carga_upload';

const mapStateToProps    = createStructuredSelector({
        open: modal.openModalSelector,
        stage: modal.stageSelector,
        progressoArquivo: modal.progressoSelector,
      }),
      mapDispatchToProps = {
        fechaModal: modal.fechaModal,
        atualizaStage: modal.atualizaStage,
        realizaUpload: modal.realizaUpload,
        cancelaUpload: modal.cancelaUpload,
        alteraProgresso: modal.alteraProgresso,
        alteraDadosArquivo: modal.alteraDadosArquivo,
      };

export default connect<*, *, *, *, *, *>(mapStateToProps, mapDispatchToProps)(ModalCargaUpload);