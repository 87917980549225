'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.5 Solicitação de Download de Documentos

Através dessa funcionalidade, é possível solicitar os arquivos XML ou PDF de um lote de documentos fiscais.

#### Requisição a enviar

    POST /api/v1/{modelo}/lista/{filtro}/download/{formato}?q={string_de_busca}

#### Parâmetros


Parâmetro       | Conteúdo
--------------- | -------------
\`modelo\`      | para NF-e: \`nfe\` <br/> para CT-e: \`cte\`
\`filtro\`      | valores para NF-e:\`emitidos\`, \`recebidos\` ou \`todos\`. <br/> \
                  valores para CT-e:\`tomados\`, \`emitidos\` ou \`todos\`.
\`formato\`     | para formato XML: \`xml\` <br/> para formato PDF: \`pdf\`
\`q\`           | para lista inteira: vazio <br/> para lista filtrada: string de busca

#### Respostas possíveis

##### 202: solicitação recebida

Indica que a solicitação foi recebida e está sendo processada. Será enviado um ID para acompanhar o progresso e retirar o download quando concluído.

\`\`\`
{
  "id": "53eb0ce86661621e4e000000",
  "formato": "xml",
  "tipo_documento": "nfe"
}
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Filtro inválido ou não reconhecido: abcdef" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. 

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X POST 'https://portal.consyste.com.br/api/v1/nfe/lista/recebidos/download/xml?q=abc'
\`\`\``;

export default function SolicitacaoDownload() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}