'use strict';
// @flow

import React from 'react';
import { autobind } from 'core-decorators';
import { Button } from 'react-bootstrap';

import ModalPoliticaSenha from './modal_politica_senha';
import Icone from 'components/common/icone';

type PoliticaSenhaProps = { quantidadeUsuarios : number, politicaSenha : {}, atualizaDescricao : (any) => void };
type PoliticaSenhaState = { showModal? : bool };

export default class PoliticaSenha extends React.PureComponent<PoliticaSenhaProps, PoliticaSenhaState> {
  props : PoliticaSenhaProps;
  state : PoliticaSenhaState = {};

  componentDidMount() {
    this.props.atualizaDescricao(this.props.politicaSenha);
  }

  @autobind
  openModal() {
    this.setState({ showModal: true });
  }

  @autobind
  onModalDone() {
    this.setState({ showModal: false });
  }

  render() {
    const { showModal } = this.state;

    const modal = showModal &&
        <ModalPoliticaSenha {...this.props} onDone={this.onModalDone}/>;

    return (
        <div>
          <Button bsStyle="default" className="pull-left" onClick={this.openModal}>
            <Icone nome="user-lock" estilo="fas">Definir Política de Senha</Icone>
          </Button>
          { modal }
        </div>
    );
  }
}