'use strict';

import 'lib/setup';
import { ErrorBoundary } from 'lib/bugsnag';

// componentes necessários para a parte 'legado' do Portal
import $ from 'jquery';
import 'jquery-ujs';
import 'jquery.cookie';
import 'bootstrap';
import 'select2';
import 'bootstrap-daterangepicker';
import '../vendor/jquery.cellPos';
import '../vendor/jquery.autocomplete';
import '../vendor/jquery.loadmask';

// componentes genéricos nossos
import './lib/legacy/ajax';
import './lib/legacy/tables';
import './lib/legacy/i18n';

// componentes específicos nossos
import './lib/legacy/domain/contas';
import './lib/legacy/domain/documentos';
import './lib/legacy/domain/manifestacao';
import './lib/legacy/domain/portaria';

// React
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import * as OurComponents from './components';

// folhas de estilos
import '../stylesheets/application.less';

// publica nossos componentes no escopo global
Object.assign(global, OurComponents);

// automatically focus on first field
$.fn.focusFirst = function() {
  $(':input:visible:enabled', this)
      .not('button, [type=submit], [type=button], .quick-search *, .navbar *')
      .first().focus();
  return this;
};

$.fn.appBindings = function() {
  // popovers e tooltips
  $('[rel=popover]', this).popover({ trigger: 'hover', container: 'body', placement: 'left' });
  $('[rel=tooltip]', this).tooltip();

  // select2
  $('select.select2', this).select2({ width: 'resolve', minimumResultsForSearch: 10, theme: 'bootstrap' });

  // quick search
  $('input[type=\'search\']', this).bind('search', function() {
    $(this).closest('form').submit();
  });

  // disable inputs on disabled table rows
  $('tr.disabled input', this).prop('disabled', true);

  $('.alert[data-remember-close]', this).each(function() {
    const c = $.cookie($(this).data('rememberClose'));
    if (c === 'closed')
      $(this).hide();
  });

  // inicializa cada date picker que encontrar
  $('input[data-rangepicker]', this).each(function() {
    $(this).attr('autocomplete', 'disabled');
    $(this).daterangepicker({
      singleDatePicker: true, autoUpdateInput: false, opens: 'center',
      locale: { cancelLabel: 'Cancelar', format: 'DD/MM/YYYY' }
    })
  });

  // react components
  $('[data-react-class]', this).each(function() {
    const $node     = $(this),
          className = $node.data('reactClass');

    // Assume className is simple and can be found at top-level (global)
    let Component = global[className];

    // Hack to support ES6
    if (Component && Component.__esModule && Component.default)
      Component = Component.default;

    if (!Component)
      throw new Error(`Could not find React component: ${ className }`);

    const props = $node.data('reactProps');

    ReactDOM.render(<ErrorBoundary><Component { ...props }/></ErrorBoundary>, this);
  });

  return this;
};

$(function() {
  const $body = $('body');
  $body
      .on('click', '.disabled', false)
      .on('click', 'a[href$=\'#\']', false)
      .on('click', '.alert[data-remember-close] .close', function() {
        $.cookie($(this).closest('.alert').data('rememberClose'), 'closed', { path: '/', expires: 30 });
      })
      .on('click', '.btn[data-loading-text]', function() {
        const $btn     = $(this),
              newWidth = $btn.is('button, input') ? $btn.outerWidth() : $btn.width();

        $btn.css('width', newWidth).button('loading');
      })
      .on('apply.daterangepicker', 'input[data-rangepicker]', (ev, picker) => $(ev.target).val(picker.startDate.format('DD/MM/YYYY')))
      .on('cancel.daterangepicker', 'input[data-rangepicker]', (ev) => $(ev.target).val(''));

  // hides empty wells
  $('.well:empty').hide();

  // automatically selects tab on load
  if (window.location.hash)
    $(`.nav-tabs li a[href='#${window.location.hash.substr(1)}']`).click();

  $('.nav-tabs').each(function() {
    if ($('li.active', this).length === 0)
      $('li:first-of-type a', this).click();
  });

  $body
      .appBindings()
      .on('ajax-loaded', function(event) { $(event.target).appBindings(); })
      .on('shown.bs.modal', '.modal', function() { $(this).focusFirst(); })
      .focusFirst();
});
