'use strict';
// @flow

import * as React from 'react';
import { Row, Col } from 'react-bootstrap';

import DocumentacaoMenu from './documentacao_menu';

import type { IRoute } from 'lib/types';

export default function DocumentacaoApp({ routes, children } : { routes : IRoute[], children? : any }) {
  return (
      <Row>
        <Col md={3}>
          <DocumentacaoMenu routes={routes}/>
        </Col>
        <Col md={9}>
          <div>
            {children}
          </div>
        </Col>
      </Row>
  );
}
