'use strict';
// @flow

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';

import { jsSelector } from '../../../../../../lib/viewUtils';
import { withProvider } from '../../../../../../lib/reduxTools';
import { atualizaPoliticaSenha, atualizaStatus, statusSelector, erroSelector, descricaoSelector } from '../../../../../../reducers/admin/politica_senha';

import ModalForm from './modal_form';

const mapStateToProps = createStructuredSelector({
  status: statusSelector,
  erro: erroSelector,
  politicaSenha: jsSelector(descricaoSelector),
});

export default compose(
    withProvider(),
    connect(mapStateToProps, { atualizaPoliticaSenha, atualizaStatus }),
)(ModalForm);
