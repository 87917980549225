'use strict';
// @flow

import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form } from 'react-bootstrap';

import { inicializaFiltro, limpaFiltro } from '../../../../reducers/arquivo/upload/filtro';

import { Input } from '../../../common/input';

type FiltrosProps = {
  handleSubmit : () => void,
  location : { query : {} },
  arquivo : { id? : string },
};

export default function Filtros({ handleSubmit, location, arquivo } : FiltrosProps) {
  const dispatch          = useDispatch(),
        inicializarFiltro = React.useCallback((id, q) => dispatch(inicializaFiltro(id, q)), [dispatch]);

  React.useEffect(() => {
    if (location && arquivo && arquivo.id)
      inicializarFiltro(arquivo.id, location.query);
  });

  const handleFormReset = React.useCallback(() => dispatch(limpaFiltro()), [dispatch]);

  return (
      <>
        <Form onSubmit={ handleSubmit }>
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header"><a className="navbar-brand">Filtros</a></div>
              <div className="navbar-form navbar-left" style={ { marginBottom: '0' } }>
                <Input name="nome" placeholder="Nome" formGroupStyle={ { width: '17em' } }
                       formControlStyle={ { width: '100%' } }/>
              </div>
              <div className="navbar-form navbar-left" style={ { marginBottom: '0' } }>
                <Input name="digestValue" placeholder="Digest Value" formGroupStyle={ { width: '17em' } }
                       formControlStyle={ { width: '100%' } }/>
              </div>
              <div className="navbar-form navbar-left" style={ { marginBottom: '0' } }>
                <Input name="chave" placeholder="Chave" formGroupStyle={ { width: '17em' } }
                       formControlStyle={ { width: '100%' } }/>
              </div>
              <div className="navbar-form navbar-right">
                <Button type="reset" onClick={ handleFormReset }>Limpar</Button> { ' ' }
                <Button type="submit" bsStyle="primary">Filtrar</Button>
              </div>
            </div>
          </nav>
        </Form>
      </>
  );
}
