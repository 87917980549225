'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.3 Consulta a Documentos

Obtém os dados de um documento constante no Portal.

#### Requisição a enviar

Para buscar um documento pelo seu ID

    GET /api/v1/{modelo}/{id}

Para buscar um documento pela sua chave

    GET /api/v1/{modelo}/{chave}

#### Parâmetros

Parâmetro     | Conteúdo
------------- | -------------
\`modelo\`    | para NF-e: \`nfe\` <br/> para CT-e: \`cte\`

#### Respostas possíveis

##### 200: dados do documento

Retorna os dados completos do documento:

\`\`\`
{
  "id": "551045bf706f725f36272201",
  "ambiente_sefaz_id": "1",
  "autorizado_em": "2015-03-23T00:00:00.000-03:00",
  "chave": "31150309004072000181550010000010331000112657",
  "cobr_data_vencimento": null,
  "created_at": "2015-03-23T13:56:31.716-03:00",
  "data_decisao_portaria": null,
  "decisao_portaria": null,
  "dest_cnpj": "10967573000142",
  "dest_email": null,
  "dest_end_uf": "SP",
  "dest_fantasia": null,
  "dest_ie": "ISENTO",
  "dest_isuframa": null,
  "dest_nome": "Consyste LTDA",
  "digest_autorizado": null,
  "digest_value": "XMyLv/v2NGRfcXhRXdTYqiddJIk=",
  "emit_cnae": null,
  "emit_cnpj": "09004072000181",
  "emit_crt": "1",
  "emit_email": null,
  "emit_end_uf": "MG",
  "emit_fantasia": "ALVO COMPUTADORES",
  "emit_ie": "0010797780068",
  "emit_im": null,
  "emit_nome": "ALVO COMPUTADORES LTDA",
  "emitido_em": "2015-03-23T00:00:00.000-03:00",
  "entrada_saida": "2015-03-23T00:00:00.000-03:00",
  "fingerprint": "fd6b092259c615c5d5dc85deae0548b6",
  "historicos": [
    {
      "id": "551045bf706f725f36262201" },
      "created_at": "2015-03-23T13:56:31.716-03:00",
      "observacoes": null,
      "operacao": "destinado",
      "updated_at": "2015-03-23T13:56:31.716-03:00",
      "usuario_id": null
    },
    { ... }
  ],
  "itens": [
    {
      "cfop": 5102,
      "codigo": "7891308759621",
      "descricao": "Fonte Universal 120W para NB",
      "ean": null,
      "ean_trib": null,
      "ncm": 85043111,
      "valor_total": 79.9,
      "valor_unitario": 79.9
    }
  ],
  "justificativa_sefaz": null,
  "lido": true,
  "lido_em": "2015-05-29T12:02:40.004-03:00",
  "manifestacao_cd": 0,
  "manifestacao_justificativa": null,
  "manifestacao_realizada_em": "2015-05-29T12:36:37.806-03:00",
  "manifestacao_realizada_por_id": "53d46705246e44641d14f701",
  "modelo_id": 55,
  "modifier_id": null,
  "municipio_fato_gerador_id": 3106200,
  "natureza_operacao": "Venda Estadual",
  "numero": 1033,
  "numero_protocolo": null,
  "observacao_portaria": null,
  "recebido_em": "2015-03-23T10:56:35.000-03:00",
  "serie": 1,
  "situacao_custodia": "ok",
  "situacao_sefaz": 100,
  "tipo_operacao_id": 1,
  "uf_id": "31",
  "ultima_consulta_em": null,
  "ultima_manifestacao_cd": 210210,
  "ultima_manifestacao_data": "2015-03-23T13:56:31.000-03:00",
  "ultima_manifestacao_descricao": "Ciencia da Operacao",
  "updated_at": "2015-05-29T12:36:52.051-03:00",
  "valor": "75.0",
  "valor_bruto": "79.9",
  "valor_total_icms": "0.0",
  "versao": "2.00",
}
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Chave ou identificador inválido" }
\`\`\`

##### 429: muitas requisições

Significa que ocorreram muitas requisições em um curto espaço de tempo utilizando sua chave de acesso.

\`\`\`
{ "error": "Muitas requisições" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Banco de dados fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfe/31150309004072000181550010000010331000112657'
\`\`\``;

export default function Introducao() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}