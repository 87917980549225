'use strict';

// @flow

import React from 'react';

import { autobind } from 'core-decorators';

import BaseComponent from 'lib/base_component';
import PaginationFooter from 'components/common/pagination_footer';

import { connectEx } from 'lib/reduxTools';
import type { IPaginatedData } from 'lib/types';

import type { IEmail } from '../constants';

import TabelaEmails from './tabela';
import Filtros from './filtros';

import * as emailsActions from '../../../reducers/emails';

type ListagemProps = {
  location : { query : any },
  listagem : IPaginatedData<IEmail>,
  caixas : any[],
  carregando : bool,
  actions : {
    listagem : (params : {}) => void
  }
}

export class Listagem extends BaseComponent<ListagemProps, *> {
  props : ListagemProps;

  @autobind
  handlePagination(page : number) {
    this.props.actions.listagem({ ...this.props.location.query, page });
  }

  render() {
    const { listagem, location, carregando, caixas } = this.props;
    const { page, total, pageSize, items } = listagem || {};

    return (
        <div>
          <Filtros location={ location } caixas={ caixas } carregando={ carregando }/>
          <div style={ { marginTop: '1em' } }>
            <TabelaEmails dados={ items } carregando={ carregando } total={ total }/>
            <PaginationFooter label="E-mails" page={ page } total={ total } pageSize={ pageSize }
                              onPageChange={ this.handlePagination }/>
          </div>
        </div>
    );
  }
}

export default connectEx(['emails'], emailsActions)(Listagem);
