'use strict';

import $ from 'jquery';

$(function() {
  // fecha a modal de exportação após enviar o formulário.
  // isso é necessário porque o formulário gera um download.
  $('.modal#exporta form').submit(function() {
    $('.modal#exporta').modal('hide');
  });
});
