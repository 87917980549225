'use strict';
// @flow

import * as _ from 'lodash';
import React from 'react';

import type { ICargaUploadDetalhes } from '../types';
import type { IRoute } from 'lib/types';
import { useEffectOnce } from 'lib/viewUtils';

import DadosGerais from './dados_gerais';
import ListaArquivos from './lista_arquivos';
import Opcoes from './opcoes';
import Filtros from './filtros';

type DetalhesUploadProps = {
  location : { query : {} },
  routes : IRoute[],
  routeParams : { id : string };
  carregaDetalhes : (id : string, params : any) => void;
  item : ICargaUploadDetalhes,
  carregando : bool,
}

export default function DetalhesUpload(props : DetalhesUploadProps) {
  useEffectOnce(() => {
    // Caso os dados do item não estejam carregados, carrega detalhes do item
    if (!props.carregando && _.isEmpty(props.item) && props.routeParams)
      props.carregaDetalhes(props.routeParams.id, props.location.query);
  });

  return (
      <div>
        <DadosGerais arquivo={ props.item }/>
        <Filtros { ...props } arquivo={ props.item }/>
        <ListaArquivos arquivo={ props.item } carregaDetalhes={ props.carregaDetalhes }/>
        <Opcoes arquivo={ props.item }/>
      </div>
  );
}
