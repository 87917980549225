'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.2 Lista de Documentos

<p style="margin-left: 5px;">Obtém uma lista com dados dos documentos constantes no Portal.</p>

#### 3.2.1 Busca Inicial

<div style="text-align: justify; margin-left: 5px;">
  É a busca realizada no primeiro momento. O retorno dessa busca corresponde à primeira página dos resultados. Caso existam mais registros do que os disponíveis na primeira página, o cliente deve solicitar a continuação da busca, utilizando o código de paginação que é retornado.
</div>

#### Requisição a enviar

* Para iniciar uma busca completa:

  <b>GET /api/v1/{modelo}/lista/{filtro}</b>

  Exemplo:
      
      GET /api/v1/nfe/lista/emitidos

* Para iniciar uma busca filtrada:

  <b>GET /api/v1/{modelo}/lista/{filtro}?q={q}</b>
      
  Exemplo:
  
      GET /api/v1/cte/lista/tomados?q=emit_nome:teste AND emit_cnpj:30634572000193

* Para retornar campos específicos:

  <b>GET /api/v1/{modelo}/lista/{filtro}?campos={campos}</b>
      
  Exemplo:
  
      GET /api/v1/nfe/lista/recebidos?campos=numero,valor

#### Parâmetros

Parâmetro           | Conteúdo
------------------- | -------------
\`modelo\`          | para NF-e: \`nfe\`<br/> para CT-e: \`cte\`
\`filtro\`          | valores para NF-e:\`emitidos\`, \`recebidos\` ou \`todos\`. <br/> valores para CT-e:\`tomados\`, \`emitidos\` ou \`todos\`.
\`q\`               | para lista inteira: vazio <br/> para lista filtrada: string de busca
\`campos\`          | lista de campos a retornar, separados por vírgula. caso não seja fornecido, \
                      serão considerados os seguintes campos:<br/> \
                      * para NF-e: \`id\`, \`chave\`, \`emitido_em\`, \`serie\`, \`numero\`, \`valor\`, \`situacao_custodia\`, \
                        \`situacao_sefaz\`, \`emit_cnpj\`, \`emit_nome\`, \`dest_cnpj\`, \`dest_nome\`.<br/>\
                      * para CT-e: \`id\`, \`chave\`, \`emitido_em\`, \`serie\`, \`numero\`, \`valor\`, \`situacao_custodia\`, \
                        \`situacao_sefaz\`, \`emit_cnpj\`, \`emit_nome\`, \`dest_cnpj\`, \`dest_nome\`, \`toma_cnpj\`, \`toma_nome\`.

#### Respostas possíveis

##### 200: lista de documentos

Retorna a quantidade total de documentos da busca, o código para a próxima página, e a lista de documentos.

\`\`\`
{
  "total": 3,
  "proxima_pagina": "c2NhbjswOzE7dG90YWxfaGl0czozOw==",
  "documentos": [
    {
      "id": "53d2f08f9711f6abe20009e7",
      "chave": "35140443283811001040550010001000151082422890",
      "emitido_em": "2014-04-08T00:00:00-03:00",
      "serie": 1,
      "numero": 100015,
      "valor": "111.4",
      "situacao_custodia": "ok",
      "situacao_sefaz": 100,
      "emit_cnpj": "43283811001040",
      "emit_nome": "Kalunga Comercio Industria Grafica Ltda",
      "dest_cnpj": "10967573000142",
      "dest_nome": "MEp Consultoria"
    },
    { ... },
    { ... }
  ]
}
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Filtro inválido ou não reconhecido: abcdef" }
\`\`\`

##### 403: ação não permitida

Significa que sua chave de acesso não tem permissão para listar documentos.

\`\`\`
{ "error": "Ação não permitida" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Serviço de busca fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfe/lista/recebidos'
\`\`\``;

export default function BuscaInicial() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}
