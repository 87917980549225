'use strict';
// @flow

import * as _ from 'lodash';
import * as React from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';

import type { ModalRecuperaXMLProps } from './types';
import { useEffectOnce } from '../../lib/viewUtils';
import { PassoRecuperacao } from './types';

export default function ModalRecuperaXML(props : ModalRecuperaXMLProps) {
  const { actions: { fechaRecuperacao } } = props;

  return (
      <Modal show onHide={ fechaRecuperacao }>
        <Modal.Header closeButton>
          <Modal.Title> Recuperação de Documento </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CorpoModal { ...props }/>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={ fechaRecuperacao }>Fechar</Button>
        </Modal.Footer>
      </Modal>
  );
}

function CorpoModal(props : ModalRecuperaXMLProps) {
  const { recuperacao: { passo, erro, download }, actions: { fechaRecuperacao } } = props;

  switch (passo) {
    case PassoRecuperacao.INICIAL:
      return <TelaInicial { ...props } />;

    case PassoRecuperacao.EM_ANDAMENTO:
      return <EmAndamento download={ download }/>;

    case PassoRecuperacao.FINAL:
      return erro ? <Falha erro={ erro }/> : <Sucesso fechaModal={ fechaRecuperacao }/>;

    default:
      return <div className="text-danger"> Passo imprevisto: { passo } </div>;
  }
}

function TelaInicial({ actions: { iniciaRecuperacao } } : ModalRecuperaXMLProps) {
  return (
      <div>
        <p className="text-justify"> Tentaremos recuperar esse XML junto à SEFAZ. Se a recuperação for possível, o documento estará disponível em
          cerca de 5 minutos (eventualmente, esse tempo pode ser maior). Clique na opção abaixo para iniciar. </p>
        <p className="text-center">
          <Button bsStyle="primary" onClick={ iniciaRecuperacao }>Solicitar Recuperação</Button>
        </p>
      </div>
  );
}

function EmAndamento() {
  return (
      <div>
        <p> Estamos tentando fazer a recuperação. Este procedimento pode demorar. </p>
        <p> Por favor, aguarde. </p>
        <ProgressBar active now={ 100 } label="Preparando início da recuperação…"/>
      </div>
  );
}

function Sucesso({ fechaModal } : { fechaModal : () => void }) {
  useEffectOnce(() => {
    fechaModal();
  });

  return null;
}

function Falha({ erro } : { erro? : any }) {
  let msg = _.get(erro, 'response.body.error') || erro;
  if (msg && msg.message)
    msg = msg.message;

  msg = String(msg);

  return (
      <div>
        <p> Infelizmente não conseguimos fazer a recuperação deste XML. O motivo da falha foi: </p>
        <blockquote className="text-danger"> { msg } </blockquote>
        <p> Você pode tentar novamente, ou entrar em contato com o suporte para maiores informações. </p>
      </div>
  );
}
