'use strict';
// @flow

import * as React from 'react';

import { autobind } from 'core-decorators';
import { FormGroup, InputGroup, ControlLabel, FormControl, HelpBlock, Button } from 'react-bootstrap';

import RailsErrors from 'rails_errors';

type FormProps = {
  chave? : string,
  consultaDocumento : { carregando : bool, erro? : any },
  focarChave? : bool,

  onChaveChanged : (e : SyntheticInputEvent<*>) => void,
  onFormSubmit : (e : SyntheticEvent<*>) => void,
  actions : {
    chaveRecebeuFoco() : void
  }
};

export default class Form extends React.PureComponent<FormProps, void> {
  props : FormProps;
  inputRef : ?HTMLElement;

  saveInputRef = (inputRef : ?HTMLElement) => {
    this.inputRef = inputRef;
  };

  @autobind
  getErroChave() {
    const { erro } = this.props.consultaDocumento;
    const errors = new RailsErrors(erro && erro.errors);
    return errors.forField('chave');
  }

  componentDidUpdate() {
    if (this.props.focarChave && this.getErroChave() && this.inputRef) {
      this.inputRef.focus();
      this.props.actions.chaveRecebeuFoco();
    }
  }

  render() {
    const { chave, consultaDocumento: { carregando }, onChaveChanged, onFormSubmit } = this.props;

    // extrai o erro de validação da chave, se houver
    const erroChave = this.getErroChave();

    return (
        <form method="post" action="/app/portaria" onSubmit={ onFormSubmit }>
          <FormGroup controlId="consulta_portaria_chave" validationState={ erroChave && 'error' }>
            <ControlLabel>Chave da NF-e / CT-e:</ControlLabel>
            <InputGroup>
              <FormControl ref={ this.saveInputRef } type="search" name="consulta_portaria[chave]" value={ chave }
                           onChange={ onChaveChanged }
                           disabled={ carregando }/>
              <InputGroup.Button>
                <Button type="submit" bsStyle="primary" disabled={ carregando }>Consultar</Button>
              </InputGroup.Button>
            </InputGroup>
            <HelpBlock>{ erroChave }</HelpBlock>
          </FormGroup>
        </form>
    );
  }
}