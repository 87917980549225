'use strict';

import React from 'react';
import { IndexRedirect, IndexRoute, Route } from 'react-router';

import BaseApp from 'components/common/base_app';
import { makeRootComponent } from 'lib/reduxTools';

import Listagem from './listagem';

const routes = (
    <Route name="Início" path="/">
      <IndexRedirect to="/app/ferramentas"/>
      <Route name="Ferramentas" path="app/ferramentas" component={ BaseApp }>
        <Route name="E-mails" title="E-mails" path="emails">
          <IndexRoute name="E-mails" subtitle="Apresenta e-mails recebidos para fins de custódia." component={ Listagem }/>
        </Route>
      </Route>
    </Route>
);

export default makeRootComponent(routes);
