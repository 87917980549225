'use strict';
// @flow

import React from 'react';

import { ProgressBar } from 'react-bootstrap';

import { formatHelper as fmt } from 'lib/viewUtils';

type UploadProgressProps = {
  nomeArquivo : ?string,
  descricao : ?string,
  bytesLoaded : number,
  bytesTotal : number,
}

export default function UploadProgress({ nomeArquivo, descricao, bytesLoaded, bytesTotal } : UploadProgressProps) {
  return (
      <div>
        <p>Seu arquivo está sendo enviado.</p>
        <p><b>Nome:</b> { nomeArquivo || '???' }</p>
        { descricao && <p><b> Descrição:</b> {descricao}</p> }
        <ProgressBar now={bytesLoaded} max={bytesTotal}
                     label={`${fmt.numberWithDelimiter(bytesLoaded / bytesTotal * 100, 0)}%`}/>
        <p>{ fmt.formatSize(bytesLoaded) } / { fmt.formatSize(bytesTotal) }</p>
      </div>
  );
}