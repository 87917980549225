'use strict';
// @flow

import React from 'react';
import { IndexRedirect, IndexRoute, Route } from 'react-router';

import { makeRootComponent } from 'lib/reduxTools';

import BaseApp from 'components/common/base_app';
import CargaUpload from './lista';
import DetalhesUpload from './detalhes';

const routes = (
    <Route name="Início" path="/">
      <IndexRedirect to="/app/ferramentas"/>
      <Route name="Ferramentas" path="app/ferramentas" component={ BaseApp }>
        <IndexRedirect to="cargas_upload"/>
        <Route name="Uploads" title="Uploads" path="cargas_upload"
               subtitle="Permite o envio de lotes de arquivos, para que sejam custodiados no Consyst-e.">
          <IndexRoute name="Uploads" component={ CargaUpload }/>
          <Route name="Detalhes do Upload" path=":id" component={ DetalhesUpload }/>
        </Route>
      </Route>
    </Route>
);

export default makeRootComponent(routes);