'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.8 Decisão Portaria de NF-e

Salva a decisão da portaria em documento NF-e.

#### Requisição a enviar

Para enviar decisão de documento NF-e:

    POST /api/v1/nfe/{chave}/decisao-portaria/{decisao}

#### Corpo da requisição

\`\`\`
{
 "observacao": "Mensagem a adicionar como observação"
}
\`\`\`

Parâmetro       | Conteúdo                                               | Opcional
--------------- | -------------                                          | ------------
\`chave\`       | chave do documento o qual será adicionado a decisão.   | não
\`decisao\`     | para "Receber Mercadoria": \`receber\` <br/> \
                  para "Devolver Mercadoria": \`devolver\` <br/>\
                  para "Receber Mercadoria com Pendência": \`receber_com_pendencia\` \
                                                                         | não
\`observacao\`  | mensagem a adicionar como observação.                  | sim

#### Respostas possíveis

##### 201: documento com decisão de portaria

\`\`\`
{
  "documento": {
    "id": "53d2f08f9711f6abe20009e7",
    "chave": "35140443283811001040550010001000151082422890",
    "emitido_em": "2014-04-08T00:00:00-03:00",
    "serie": 1,
    "numero": 100015,
    "valor": "111.4",
    "situacao_custodia": "ok",
    "situacao_sefaz": 100,
    "emit_cnpj": "43283811001040",
    "emit_nome": "Kalunga Comercio Industria Grafica Ltda",
    "dest_cnpj": "10967573000142",
    "dest_nome": "MEp Consultoria",
    "decisao_portaria": "receber",
    "data_decisao_portaria": "2018-06-19T17:03:20-03:00",
    "observacao_portaria": "Recebido"
  }
}
\`\`\`

##### 422: Requisição inválida

Significa que o corpo da requisição não segue o formato especificado.

\`\`\`
{ "error": "Requisição não atende ao formato especificado" }
\`\`\`

##### 404: Documento não encontrado

Significa que o usuário não tem permissão para alterar o documento.

\`\`\`
{ "error": "Documento não encontrado" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X POST 'https://portal.consyste.com.br/api/v1/nfe/35171107764744000121550010000041351402532164/decisao-portaria/receber'
     -d '{"observacoes": "Mensagem a adicionar como observação"}'
\`\`\``;

export default function DecisaoPortaria() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}