'use strict';
// @flow

import React from 'react';
import { autobind } from 'core-decorators';
import { Col, Label, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';

import request from 'lib/request';
import { formatHelper as fmt } from 'lib/viewUtils';

type OperacaoState = { c : 'success' | 'danger' | 'info' | 'warning', n : number, t : string };

type POLState = {
  p : { s : OperacaoState[], total? : number },
  tooltipId : string,
};

type POLProps = {
  p : { s : OperacaoState[], total? : number },
  url : string,
  incluiLegenda : bool,
};

let nextId = 0;

export default class ProgressoOperacaoLote extends React.PureComponent<POLProps, POLState> {
  state : POLState;
  props : POLProps;
  tm : ?TimeoutID;

  constructor() {
    super();
    this.state = { p: this.props.p || { s: [] }, tooltipId: `progresso-lote-tooltip-${++nextId}` };
  }

  componentDidMount() {
    if (this.props.url)
      this.refresh();
  }

  componentWillUnmount() {
    clearTimeout(this.tm);
  }

  @autobind
  scheduleRefresh() {
    if (this.props.url)
      this.tm = setTimeout(this.refresh, 1500);
  }

  @autobind
  refresh() {
    if (!this.props.url)
      throw new Error('Refresh called, but the URL was not specified.');

    request.get(this.props.url)
        .accept('json')
        .then(r => this.setState({ p: r.body }))
        .then(() => this.scheduleRefresh());
  }

  render() {
    const t = this.state.p.total;
    const statusWithValues = this.state.p.s.filter(p => p.n);

    const hint = [];
    const bars = statusWithValues.map(p => {
      const pct  = !t ? 0 : p.n / t * 100,
            fnum = fmt.numberWithDelimiter(p.n),
            fpct = `${fmt.numberWithDelimiter(pct)}%`;

      hint.push(`${p.t}: ${fnum}`);

      return <ProgressBar bsStyle={ p.c } key={ p.c } now={ pct } label={ fpct }/>;
    });

    const tip = hint.length > 0 &&
        <Tooltip id={ this.state.tooltipId }> { hint.map(h => <span key={ h }>{ h }<br/></span>) } </Tooltip>;

    let pb = <ProgressBar>{ bars }</ProgressBar>;
    if (tip)
      pb = <OverlayTrigger overlay={ tip } placement="bottom">{ pb }</OverlayTrigger>;

    if (!this.props.incluiLegenda)
      return <div>{ pb }</div>; // necessário incluir em um <div>, para que ative a tooltip corretamente

    // se chegamos aqui, é pq devemos exibir legenda

    const labelsLegenda = statusWithValues.map(p =>
        <span key={ p.t }>{ ' ' }
          <span className={ `label label-${p.c}` }>{ p.t }: { fmt.numberWithDelimiter(p.n) }</span>
        </span>
    );

    return (
        <div>
          { pb }
          <hr style={ { margin: '8px 5px 5px 5px' } }/>
          <Row>
            <Col xs={ 3 }>
              Legenda:
            </Col>
            <Col xs={ 9 }>
              <Label bsStyle="default">
                Total: { fmt.numberWithDelimiter(t) }
              </Label>
              { labelsLegenda }
            </Col>
          </Row>
        </div>
    );
  }
}
