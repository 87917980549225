'use strict';
// @flow

import * as React from 'react';
import moment from 'moment';
import { Table } from 'react-bootstrap';

import type { IActions, IConsulta } from './types';
import { formatHelper as fmt } from 'lib/viewUtils';

import { formatoTela } from '../common/detalhes_documento/formato_tela';

interface DadosDocumentoProps {
  consulta : IConsulta;
  actions : IActions;
}

export default function DadosDocumento({ consulta, actions } : DadosDocumentoProps) {
  const handleChaveClick = React.useCallback(e => actions.consultaChave(e.innerHTML), [actions]);

  return (
      <Table striped bordered className="dados-documento">
        <colgroup>
          <col width={ 90 }/>
          <col width={ 120 }/>
          <col width={ 90 }/>
          <col width={ 120 }/>
          <col width={ 90 }/>
          <col width={ 120 }/>
        </colgroup>
        <tbody>
        <tr>
          <th scope="row">Número</th>
          <td>{ fmt.numberWithDelimiter(consulta.numero) }</td>
          <th scope="row">Modelo</th>
          <td>{ formatoTela('modelo', consulta.modelo) }</td>
          <th scope="row">Emissão</th>
          <td><DataEmissao consulta={ consulta }/></td>
        </tr>
        <tr>
          <th scope="row">Série</th>
          <td>{ consulta.serie }</td>
          <th scope="row">Valor Total</th>
          <td colSpan={ 3 }>{ fmt.numberWithDelimiter(consulta.valor_total, 2, { prefix: 'R$ ' }) }</td>
        </tr>
        <tr>
          <th scope="row">Chave</th>
          <td colSpan={ 5 }>{ fmt.formatId(consulta.chave) }</td>
        </tr>
        <tr>
          <th scope="row">Emitente</th>
          <td colSpan={ 5 }>{ fmt.formatId(consulta.emit_cnpj) } { consulta.emit_nome }</td>
        </tr>
        <tr>
          <th scope="row">Destinatário</th>
          <td colSpan={ 5 }>{ fmt.formatId(consulta.dest_cnpj) } { consulta.dest_nome }</td>
        </tr>
        { consulta.chaves_nfe && consulta.chaves_nfe.length > 0 &&
        <tr>
          <th scope="row">NF-e(s)</th>
          <td colSpan={ 5 }>{ consulta.chaves_nfe.map(ch =>
              <a key={ ch } onClick={ handleChaveClick }>{ ch }</a>,
          ) }</td>
        </tr>
        }
        </tbody>
      </Table>
  );
}

export function DataEmissao({ consulta } : { consulta : IConsulta }) {
  if (consulta.data_emissao) {
    return (
        <time dateTime={ consulta.data_emissao }>
          { moment(consulta.data_emissao).format('DD/MMM/YYYY').toLowerCase() }
        </time>
    );
  }

  const ano = Number(`20${ consulta.chave.substr(2, 2) }`),
        mes = Number(consulta.chave.substr(4, 2).replace(/^0+/, '')),
        m   = moment([ano, mes - 1]);
  return <time>{ m.isValid() ? m.format('MMM / YYYY').toLowerCase() : '–' }</time>;
}
