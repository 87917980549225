// @flow
'use strict';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form/immutable';
import { createStructuredSelector } from 'reselect';

import * as decisaoMercadoria from '../../../reducers/decisaoMercadoria';
import { fechaDecisao } from '../../../reducers/decisaoPortaria';

import ModalDecisaoPortaria from './modal_decisao_portaria';

const mapStateToProps = createStructuredSelector({ initialValues: decisaoMercadoria.rootSelector });

export default compose(
    connect<*, *, *, *, *, *>(mapStateToProps, { fechaDecisao }),
    reduxForm({
      form: 'decisaoMercadoria', enableReinitialize: true,
      onSubmit(form, dispatch) {
        dispatch(decisaoMercadoria.salva(form));
      },
    }),
)(ModalDecisaoPortaria);