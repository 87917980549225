'use strict';
// @flow

import * as React from 'react';
import { Nav, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

import type { IRoute } from 'lib/types';

export default function DocumentacaoMenu({ routes } : { routes : IRoute[] }) {
  const rootRoute = routes[0];

  let items = [rootRoute.indexRoute];

  if (rootRoute.childRoutes)
    items = items.concat(rootRoute.childRoutes);

  return (
      <Nav bsStyle="pills" stacked>
        { items.map((r, k) => {
          if (!r)
            return null;

          if (r.childRoutes) {
            return (
                <li key={ k }>
                  <a href={ `/doc/api/${ r.childRoutes[0].path || '' }` }>{ r.title }</a>
                  <Nav bsStyle="pills" stacked className="subitem-doc-api">
                    { r.childRoutes.map(rSubitem => rSubitem.title && renderLink(rSubitem)) }
                  </Nav>
                </li>
            );
          }

          return renderLink(r);
        }) }
      </Nav>
  );
}

function renderLink(r? : IRoute) {
  if (!r || !r.path)
    return null;

  return (
      <LinkContainer key={ r.path } to={ `/doc/api/${ r.path }` }>
        <NavItem>{ r.title }</NavItem>
      </LinkContainer>
  );
}
