'use strict';
// @flow

import $ from 'jquery';
import * as _ from 'lodash';
import * as React from 'react';

import { autobind } from 'core-decorators';
import { Modal, Button, Alert, ProgressBar } from 'react-bootstrap';

type ModalSolicitaXMLProps = {
  consulta? : ?{ url : string },
  onClose? : (any) => void,
};

type ModalSolicitaXmlState = {
  stage : ?string,
  error? : Error | { message : string },
};

const INITIAL_STATE = { stage: null };

export default class ModalSolicitaXML extends React.PureComponent<ModalSolicitaXMLProps, ModalSolicitaXmlState> {
  props : ModalSolicitaXMLProps;
  state : ModalSolicitaXmlState = INITIAL_STATE;
  xhr : XMLHttpRequest;

  @autobind
  onSubmitOK() {
    this.setState({ stage: 'ok' });
  }

  @autobind
  onSubmitFailed(xhr : XMLHttpRequest) {
    let msg;
    _.attempt(() => msg = JSON.parse(xhr.responseText).error);
    if (msg && msg.message)
      msg = msg.message;
    if (!msg)
      msg = `${xhr.status}: ${xhr.statusText}`;

    this.setState({ stage: 'error', error: { message: msg } });
  }

  @autobind
  handleSubmit() {
    const consultaUrl = this.props.consulta && this.props.consulta.url;
    if (!consultaUrl)
      return alert('Consulta em branco');

    this.setState({ stage: 'sending' });
    const url = `${consultaUrl}/solicitar_xml`;

    this.xhr = $.ajax({ method: 'post', url, dataType: 'json', data: { _method: 'patch' } })
        .done(this.onSubmitOK)
        .fail(this.onSubmitFailed);
  }

  @autobind
  handleCancel() {
    this.xhr && this.xhr.abort();
    this.setState({ stage: 'cancelled' });
  }

  @autobind
  handleClose() {
    this.setState(INITIAL_STATE);
    _.attempt(() => this.xhr.abort());
    if (this.props.onClose)
      this.props.onClose(this);
  }

  render() {
    const { body, buttons } = this.renderBodyAndButtons();

    return (
        <Modal show onHide={ this.handleClose }>
          <Modal.Header closeButton>
            <Modal.Title> Solicitar XML </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { body }
          </Modal.Body>
          <Modal.Footer>
            { buttons }
          </Modal.Footer>
        </Modal>
    );
  }

  renderBodyAndButtons() {
    let body, buttons;

    const stage = this.state.stage;

    switch (stage) {
      case null:
        // estágio inicial
        body = <div>
          <p>Será realizada a manifestação de “ciência da operação” pelo destinatário
            e o download do documento junto à SEFAZ.</p>
          <p>Confirma?</p>
        </div>;

        buttons = <div>
          <Button onClick={ this.handleClose }> Não </Button>
          <Button bsStyle="primary" onClick={ this.handleSubmit }> Sim, confirmo a solicitação </Button>
        </div>;

        break;

      case 'sending':
        // enviando
        body = <div>
          <p>Aguarde, estamos enviando a manifestação de “ciência da operação”
            e solicitando o download do documento junto à SEFAZ…</p>
          <ProgressBar active now={ 100 }/>
        </div>;

        buttons = <div>
          <Button onClick={ this.handleCancel }> Cancelar </Button>
          <Button bsStyle="primary" disabled> Aguarde… </Button>
        </div>;

        break;

      case 'ok':
        // envio OK
        body = <div>
          <p>Solicitamos o download desta NF-e à SEFAZ. Se a NF-e existir e estiver dentro do prazo,
            recuperaremos em alguns minutos.</p>
          <p>Aguarde e consulte novamente.</p>
        </div>;
        buttons = <Button onClick={ this.handleClose }> Fechar </Button>;
        break;

      case 'error':
      case 'cancelled':
        // envio com erro
        if (stage === 'cancelled')
          body = <p>Você cancelou a solicitação do XML.</p>;
        else if (this.state.error) {
          body = <div>
            <p>Ocorreu um erro ao solicitar o documento:</p>
            <Alert bsStyle="danger" bsSize="xs">{ this.state.error.message }</Alert>
            <p>Tente novamente mais tarde.</p>
          </div>;
        }
        else {
          body = <div>
            <p>Ocorreu um erro ao solicitar o documento.</p>
            <p>Tente novamente mais tarde.</p>
          </div>;
        }

        buttons = <div>
          <Button onClick={ this.handleClose }> Fechar </Button>
          <Button bsStyle="primary" onClick={ this.handleSubmit }> Tentar novamente </Button>
        </div>;
        break;
    }

    return { body, buttons };
  }
}
