'use strict';
// @flow

import _ from 'lodash';
import React from 'react';
import { Button, ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { autobind } from 'core-decorators';

import BaseComponent from 'lib/base_component';
import UploadProgress from '../upload_progress';

type ModalCargaUploadProps = {
  stage : string | null,
  open : bool,
  fechaModal : () => void,
  atualizaStage : (string | null) => void,
  realizaUpload : (file : any, description : string) => void,
  cancelaUpload : () => void,
  alteraProgresso : (params : any) => void,
  alteraDadosArquivo : (nomeArquivo : string, descricao : string) => void,
  progressoArquivo : { nomeArquivo : ?string, descricao : ?string, bytesLoaded : number, bytesTotal : number }
}

export default class ModalCargaUpload extends BaseComponent<ModalCargaUploadProps, *> {
  props : ModalCargaUploadProps;

  amazonForm : ?HTMLFormElement;

  @autobind
  close() {
    const locked = _.result(window, 'onbeforeunload');
    if (!locked || confirm(locked)) {
      this.props.fechaModal();
      this.props.cancelaUpload();
    }
  }

  /**
   * Chamado quando o usuário solicita o cancelamento do upload.
   */
  @autobind
  handleCancelAction() {
    this.props.cancelaUpload();
  }

  /**
   * Trava a navegação do usuário, para evitar que o upload seja interrompido.
   */
  lockNavigation() {
    window.onbeforeunload = () => 'Você está tentando navegar para outra página. O upload será interrompido.\n\n' +
        'Clique em "cancelar" para continuar na mesma página e continuar o upload.';
  }

  /**
   * Chamado quando o usuário inicia o upload.
   */
  @autobind
  handleSubmit(e : SyntheticEvent<*>) {
    e.preventDefault();

    const form : any = this.amazonForm,
          file       = form.file.files[0],
          descricao  = form.descricao.value;

    this.props.alteraProgresso({ bytesTotal: 0, bytesLoaded: 0 });
    this.props.alteraDadosArquivo(file.name, descricao);

    this.props.atualizaStage('sending');

    this.lockNavigation();

    this.props.realizaUpload(file, descricao);
  }

  formRef = (form : ?HTMLFormElement) => {
    this.amazonForm = form;
  };

  render() {
    return (
        <Modal show={ this.props.open } onHide={ this.close }>
          <Modal.Header>
            <Modal.Title>Upload de documentos</Modal.Title>
          </Modal.Header>
          { this.props.stage === 'ready' ? this.renderForm() : this.renderInfo() }
        </Modal>
    );
  }

  renderForm() {
    let buttonFormAction;
    let buttonFormCancel;

    if (this.props.stage === 'ready') {
      buttonFormAction = <Button type="submit" bsStyle="primary">Enviar</Button>;
      buttonFormCancel = <Button onClick={ this.close }>Fechar</Button>;
    }
    else {
      buttonFormAction = <Button type="submit" bsStyle="primary">Tentar novamente</Button>;
      buttonFormCancel = <Button onClick={ this.handleCancelAction }>Cancelar</Button>;
    }

    return (
        <div>
          <form ref={ this.formRef } encType="multipart/form-data" onSubmit={ this.handleSubmit }>
            <Modal.Body>
              <p>Você pode enviar arquivos de até <kbd>1GB</kbd> através desta função.</p>
              <p>Após o envio, os XMLs serão processados, por ordem de chegada.
                Se houverem outros envios na fila, o seu envio pode demorar até mesmo alguns dias.</p>
              <FormGroup controlId="descricao">
                <ControlLabel>Descrição</ControlLabel>
                <FormControl type="text"/>
              </FormGroup>

              <FormGroup controlId="file">
                <ControlLabel>Arquivo</ControlLabel>
                <FormControl type="file" required/>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              { buttonFormCancel }
              { buttonFormAction }
            </Modal.Footer>
          </form>
        </div>
    );
  }

  renderInfo() {
    let body, buttons;

    switch (this.props.stage) {
      case 'building':
        body = 'Aguarde, solicitando autorização para upload…';
        break;

      case 'build_error':
        body = <div>
          <p>Ocorreu um erro ao solicitar autorização para upload.</p>
          <p>Experimente atualizar a página. Caso o erro persista, entre em contato com o suporte.</p>
        </div>;
        break;

      case 'upload_error':
        body = <div>
          <p>Ocorreu um erro ao enviar seu upload.</p>
          <p>Experimente atualizar a página. Caso o erro persista, entre em contato com o suporte.</p>
        </div>;
        break;

      case 'cancelled':
        body = <p>O envio do arquivo foi cancelado.</p>;
        break;

      case 'finished':
        body = <p>Seu arquivo foi enviado com sucesso.</p>;
        break;

      case 'sending':
        body = <UploadProgress { ...this.props.progressoArquivo } />;
        buttons = <Button onClick={ this.handleCancelAction }>Cancelar</Button>;
        break;

      default:
        body = 'Ocorreu um erro, por favor tente novamente';
        break;
    }

    return (
        <div>
          <Modal.Body>
            { body }
          </Modal.Body>
          <Modal.Footer>
            { buttons || <Button onClick={ this.close }> Fechar</Button> }
          </Modal.Footer>
        </div>
    );
  }
}