'use strict';
// @flow

import { createStructuredSelector } from "reselect";
import { compose } from 'redux';
import { connect } from 'react-redux';

import * as listaDownload from '../../reducers/arquivo/download/lista';
import { withProvider } from '../../lib/reduxTools';
import { jsSelector } from '../../lib/viewUtils';

import Downloads from './downloads';

const mapStateToProps    = createStructuredSelector({
        downloads: jsSelector(listaDownload.currentPage),
        page:listaDownload.pageSelector,
      }),
      mapDispatchToProps = {
        carregaLista: listaDownload.carregaLista,
        alteraAtualizaTela: listaDownload.alteraAtualizaTela,
      };

export default compose(
    withProvider(),
    connect(mapStateToProps, mapDispatchToProps)
)(Downloads);
