'use strict';
// @flow

import * as React from 'react';

import { Button } from 'react-bootstrap';

import AlertWithIcon from 'components/common/alert_with_icon';

import { connectEx } from 'lib/reduxTools';

import AlertaAvaliacao from './alerta_avaliacao';
import ModalDecisaoPortaria from './modal_decisao_portaria';

import * as decisaoPortariaActions from '../../reducers/decisaoPortaria';
import type { DecisaoPortariaProps } from './types';

import { TEXTO_POR_SITUACAO } from 'i18n/portaria.pt';

export function DecisaoPortaria(props : DecisaoPortariaProps) {
  const { consulta, decisao: { escolhendo }, actions: { decisaoSelecionada } } = props;
  const { documento } = consulta;

  if (!documento || !documento.custodiado) {
    return <div className="panel-body info-portaria nao-encontrado">
      <AlertWithIcon bsStyle="danger" icon="exclamation-triangle" markdown={ TEXTO_POR_SITUACAO['not_found'] }/>
    </div>;
  }

  if (consulta.cte) {
    return <div className="panel-body info-portaria eh-cte">
      <AlertWithIcon bsStyle="warning" icon="exclamation-triangle" markdown={ TEXTO_POR_SITUACAO['cte'] }/>
    </div>;
  }

  if (documento && consulta.permite_informar_situacao_recebimento && consulta.permite_decisoes_portaria) {
    return <div>
      <div className="panel-body info-portaria">
        <AlertaAvaliacao consulta={ consulta }/>
      </div>
      <div className="panel-footer decisao-portaria c" style={ { padding: '1.5em 0' } }>
        <BotaoDecisao decisao="receber" bsStyle="success" handler={ decisaoSelecionada }>
          Receber Mercadoria
        </BotaoDecisao> { ' ' }
        <BotaoDecisao decisao="devolver" bsStyle="danger" handler={ decisaoSelecionada }>
          Devolver Mercadoria
        </BotaoDecisao> { ' ' }
        <BotaoDecisao decisao="receber_com_pendencia" bsStyle="warning" handler={ decisaoSelecionada }>
          Receber Mercadoria com Pendência
        </BotaoDecisao>
      </div>
      { escolhendo && <ModalDecisaoPortaria { ...props }/> }
    </div>;
  }

  return null;
}

type BotaoDecisaoProps = {
  decisao : string,
  handler : (decisao : string, titulo : string) => void,
  children? : any,
};

function BotaoDecisao({ decisao, children, handler, ...props } : BotaoDecisaoProps) {
  const onClick = React.useCallback(e => handler(decisao, e.target.innerHTML), [handler, decisao]);

  return (
      <Button { ...props } onClick={ onClick }>{ children }</Button>
  );
}

/**
 * Conecta o componente à store do Redux e às actions.
 */
export default connectEx(state => ({ decisao: state.get('decisaoPortaria').toJS() }), decisaoPortariaActions)(DecisaoPortaria);
