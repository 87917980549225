'use strict';

export const TEXTO_POR_SITUACAO = {
  not_found: `Documento não custodiado.`,

  cte: `O documento pesquisado é um CT-e.
Para receber mercadorias, consulte a NF-e relacionada.`,

  already_decided: `A nota já foi consultada na Portaria, e a decisão foi: **%{decision}**.`,

  ok: `A nota pesquisada está custodiada e com arquivo **OK**.`,

  not_ok: `Foram verificadas irregularidades nesta nota. Consulte a caixa *Situações* ao lado para verificar.
Você também pode tentar obter o documento da SEFAZ, clicando em “Recuperar XML”.`
};
