'use strict';
// @flow

import React from 'react';
import { autobind } from 'core-decorators';
import { Modal } from 'react-bootstrap';

import ModalForm from './modal_form';

export default class ModalPoliticaSenha extends React.PureComponent<*, *> {
  form : ModalForm;

  @autobind
  handleModalClose() {
    this.form.close();
  }

  @autobind
  refForm(form : any) {
    this.form = form;
  }

  render() {
    return (
        <Modal show onHide={ this.handleModalClose }>
          <ModalForm ref={ this.refForm } { ...this.props }/>
        </Modal>
    );
  }
}