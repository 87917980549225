'use strict';
// @flow

import * as React from 'react';
import { autobind } from 'core-decorators';

import { fetchJSON } from 'lib/api/fetch';
import { formatHelper as fmt } from 'lib/viewUtils';

type ProgressoRecuperacaoProps = { baseUrl : string, incluiLegenda? : bool };
type ProgressoRecuperacaoState = { p : { s : IStatus[], total? : number } };
type IStatus = { c : string, t : string, n : number };

export default class ProgressoRecuperacao extends React.PureComponent<ProgressoRecuperacaoProps, *> {
  props : ProgressoRecuperacaoProps;
  state : ProgressoRecuperacaoState = { p: { s: [] } };

  tm : ?TimeoutID;

  componentDidMount() {
    this.refresh().then();
  }

  componentWillUnmount() {
    clearTimeout(this.tm);
  }

  @autobind
  async refresh() {
    try {
      const p = await fetchJSON(`${this.props.baseUrl}/progresso`);

      this.setState({ p });
      this.tm = setTimeout(this.refresh, 5000);
    }
    catch (e) {
      this.tm = setTimeout(this.refresh, 50000);
    }
  }

  render() {
    const { p } = this.state;

    const bars = p.s.map(s => {
      const pct = !p.total ? 0 : s.n / p.total * 100;
      const fpct = `${fmt.numberWithDelimiter(pct)}%`;

      return <div className={ `progress-bar progress-bar-${s.c}` } key={ s.c }
                  role="progressbar"
                  style={ { width: `${pct}%` } }
                  title={ `${s.t}: ${fpct}` } rel="tooltip">{ fpct }</div>;
    });

    return (
        <div>
          <div className="progress">{ bars }</div>
          { this.props.incluiLegenda && <Legenda p={ p }/> }
        </div>
    );
  }
}

function Legenda({ p } : ProgressoRecuperacaoState) {
  return (
      <div>
        <hr style={ { margin: '8px 5px 5px 5px' } }/>
        <div className="row">
          <div className="col-xs-3">
            Legenda:
          </div>
          <div className="col-xs-9">
            <span className="label label-default">Total: { fmt.numberWithDelimiter(p.total) }</span>
            { p.s.map(s => <ItemLegenda key={ s.t } s={ s }/>) }
          </div>
        </div>
      </div>
  );
}

function ItemLegenda({ s } : { s : IStatus }) {
  return (
      <span>{ ' ' }
        <span className={ `label label-${s.c}` }>
          { s.t }: { fmt.numberWithDelimiter(s.n) }
        </span>
      </span>
  );
}
