'use strict';
// @flow

import React from 'react';

import { formatHelper as fmt } from '../../../lib/viewUtils';

import type { IArquivo } from '../types';

import DataTable, { Column } from '../../common/data_table';
import { DescricaoIcone } from '../../common/descricao';

type TableComponentProps = {
  dados : IArquivo[],
}

export default function TableComponent({ dados } : TableComponentProps) {
  return (
      <DataTable data={ dados } striped hover>
        <Column expr="nome" width={ 100 }>Nome do Arquivo</Column>
        <Column expr="tipo" widtath={ 30 }>Tipo</Column>
        <Column expr="tamanho" width={ 30 } formatter={ formataTamanho }>Tamanho</Column>
        <Column expr="chave" width={ 50 }>Chave</Column>
        <Column expr="status_sintaxe" width={ 50 } formatter={ formataStatus }
                headerHint="Status Sintaxe">Syn</Column>
        <Column expr="status_schema" width={ 50 } formatter={ formataStatus }
                headerHint="Status Schema">Sch</Column>
        <Column expr="status_ambiente" width={ 50 } formatter={ formataStatus }
                headerHint="Status Ambiente">Amb</Column>
        <Column expr="status_assinatura" width={ 50 } formatter={ formataStatus }
                headerHint="Status Assinatura">Ass</Column>
        <Column expr="em_uso,documento_path" width={ 30 } formatter={ formataSimNao }>Em Uso</Column>
      </DataTable>
  );
}

function formataTamanho(n : any) {
  return fmt.formatSize(n);
}

function formataSimNao([u, url] : any) {
  return u === true ? <a href={ url }>Sim</a> : 'Não';
}

function formataStatus(e : any) {
  return <DescricaoIcone tipo="situacao_arquivo" codigo={ e || 'default' }/>;
}
