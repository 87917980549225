'use strict';

import React from 'react';
import Markdown from 'components/common/markdown';

const texto = `## 3 Operações

### 3.2 Lista de Documentos

<p style="margin-left: 5px;">Obtém uma lista com dados dos documentos constantes no Portal.</p>

#### 3.2.2 Continuação de uma busca

<div style="text-align: justify; margin-left: 5px;">
  A continuação de uma busca corresponde às páginas seguintes da busca básica. A continuação é acessada somente através do código que a API envia como retorno na busca básica. Para cada página de retorno é necessário enviar o código da paginação, que foi obtido na página anterior.
</div> 

#### Requisição a enviar

* Para realizar a continuação de uma busca:

  <b>GET /api/v1/{modelo}/lista/continua/{codigo_paginacao}</b>

  Exemplo:
      
      GET /api/v1/nfe/lista/continua/c2NhbjswOzE7dG90YWxfaGl0czozOw==

#### Parâmetros

Parâmetro           | Conteúdo
------------------- | -------------
\`modelo\`          | para NF-e: \`nfe\`<br/> para CT-e: \`cte\`
\`codigo_paginacao\`| na primeira requisição: não informar <br/> nas demais: o código recebido na requisição anterior

#### Respostas possíveis

##### 200: lista de documentos

Retorna a quantidade total de documentos da busca, o código para a próxima página, e a lista de documentos.

\`\`\`
{
  "total": 3,
  "proxima_pagina": "c2NhbjswOzE7dG90YWxfaGl0czozOw==",
  "documentos": [
    {
      "id": "53d2f08f9711f6abe20009e7",
      "chave": "35140443283811001040550010001000151082422890",
      "emitido_em": "2014-04-08T00:00:00-03:00",
      "serie": 1,
      "numero": 100015,
      "valor": "111.4",
      "situacao_custodia": "ok",
      "situacao_sefaz": 100,
      "emit_cnpj": "43283811001040",
      "emit_nome": "Kalunga Comercio Industria Grafica Ltda",
      "dest_cnpj": "10967573000142",
      "dest_nome": "MEp Consultoria"
    },
    { ... },
    { ... }
  ]
}
\`\`\`

##### 400: requisição inválida

Significa que a requisição não foi aceita. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Código de paginação inválido ou não reconhecido: abcdef" }
\`\`\`

##### 403: ação não permitida

Significa que sua chave de acesso não tem permissão para listar documentos.

\`\`\`
{ "error": "Ação não permitida" }
\`\`\`

##### 500: erro interno do servidor

Ocorreu um erro no processamento. A mensagem de erro irá informar mais detalhes.

\`\`\`
{ "error": "Serviço de busca fora do ar" }
\`\`\`

#### Exemplos

##### cURL

\`\`\`bash
curl -H 'X-Consyste-Auth-Token: Yuom51QL22X_RNsJpG-z' 
     -H 'Content-Type: application/json' 
     -X GET 'https://portal.consyste.com.br/api/v1/nfe/lista/recebidos'
\`\`\``;

export default function ContinuacaoBusca() {
  return (
      <div>
        <Markdown text={ texto }/>
      </div>
  );
}
