'use strict';
// @flow

import type { IDocumento } from '../../lib/api';

export const PassoRecuperacao = { INICIAL: 0, EM_ANDAMENTO: 1, FINAL: 2 };

export type ModalRecuperaXMLProps = {
  recuperacao : IRecuperacao,
  actions : IActions
}

export type IRecuperacao = {
  aberta : bool,
  download? : {},
  passo : $Values<typeof PassoRecuperacao>,
  erro? : any,
}

export type ResultadoProps = {
  consulta? : ?IConsulta,
  consultaDocumento : { carregando : bool, erro? : { message? : string } },
  consultaSefaz? : ?IResultadoConsultaSefaz,

  actions : IActions,
}

export type IResultadoConsultaSefaz = {
  carregando : bool,
}

export type IActions = {
  setChave(chave : ?string) : void,
  transfereFocoChave() : void,
  chaveRecebeuFoco() : void,

  consultaChave(chave : ?string) : void,

  abreRecuperacao() : void,
  fechaRecuperacao() : void,
  iniciaRecuperacao() : void,

  abreSolicitacaoXml() : void,
  fechaSolicitacaoXml() : void,

  decisaoSelecionada(decisao : string, titulo : string) : void,
  salvaDecisao(obs : ?string) : void,
  fechaDecisao() : void,
}

export type IConsulta = {
  url : string,
  chave : string,
  data_emissao? : string,
  serie? : number,
  numero? : number,
  modelo? : number,
  cte? : bool,
  valor_total? : string,
  emit_cnpj? : string,
  dest_cnpj? : string,
  emit_nome? : string,
  dest_nome? : string,
  chaves_nfe? : string[],

  documento? : ?IDocumento,

  aceite_recomendado : bool,
  permite_solicitar_xml : bool,
  permite_recuperar_xml : bool,
  permite_imprimir_pdf : bool,
  permite_download_xml : bool,
  permite_decisoes_portaria : bool,
  permite_ver_mais_informacoes : bool,
  permite_informar_situacao_recebimento : bool,
  itens? : IItem[]
}

export type DecisaoPortariaProps = {
  consulta : IConsulta,
  decisao : IDecisao,

  actions : IActions,
}

export type IDecisao = {
  opcaoSelecionada : {
    decisao : string,
    titulo : string,
  },

  escolhendo : bool,
  enviando : bool,
  erro? : string,
}

export type IItem = {
  pendente : ?bool,
  codigo : string,
  descricao : ?string,
  pedido_nro : ?string,
  pedido_item : ?number,
  quantidade? : number,
}